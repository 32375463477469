import { useQuery } from "@tanstack/react-query";
import { apiClient, RequestPath } from "utils/api-client";
import { QueryForRecord } from "types/query-for-record";
import { DataSource } from "features/privacy-app/api/data-sources";

export interface Profile {
  id: string;
  contacts: ProfileContact[];
  is_phone_call_enabled: boolean;
  is_phone_call_enabled_updated_at: string;
  is_sms_enabled: boolean;
  is_sms_enabled_updated_at: string;
  is_email_enabled: boolean;
  is_email_enabled_updated_at: string;
  is_letter_enabled: boolean;
  is_letter_enabled_updated_at: string;
  is_unsubscribed_all_enabled: boolean;
  is_unsubscribed_all_enabled_updated_at: string;
}

export interface ProfileContact {
  id: string;
  data_source: DataSource;
  remote_id: string;
  names: { id: string; first_name: string; last_name: string }[];
  emails: { id: string; email: string }[];
}

export interface ProfileStats {
  total_profiles: number;
  total_contacts: number;
}

const DATA_SOURCE_KEY = "profile";

function searchProfilesByEmail(
  email: string | undefined
): QueryForRecord<Profile[]> {
  return {
    enabled: !!email,
    queryKey: [DATA_SOURCE_KEY, email],
    queryFn: async () => {
      return (
        await apiClient.get(("/profiles?email_partial=" + email) as RequestPath)
      ).data as Profile[];
    },
  };
}

function getProfileStats(): QueryForRecord<ProfileStats> {
  return {
    queryKey: [DATA_SOURCE_KEY, "stats"],
    queryFn: async () => {
      return (await apiClient.get("/profiles/stats" as RequestPath))
        .data as ProfileStats;
    },
  };
}

export function useProfile() {
  return {
    searchProfilesByEmail: (email: string | undefined) =>
      useQuery(searchProfilesByEmail(email)),
    getProfileStats: () => useQuery(getProfileStats()),
  };
}
