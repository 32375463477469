import { Heading, Text, VStack } from "@chakra-ui/react";
import { map } from "utils/collection";
import { DataRequestBanner } from "features/customer-dashboard/screens/data-requests/request-banner";
import {
  DATA_REQUESTS,
  RequestFormProps,
} from "features/customer-dashboard/screens/data-requests/constants";

export function DataRequestPage() {
  return (
    <>
      <VStack spacing={[6, 8]} align={"flex-start"} mb={[8, 16]}>
        <Heading as={"h1"} size={"lg"}>
          Manage your data
        </Heading>
        <Text color={"gray.600"}>
          You have the right to request a coy of your data, to have it changed,
          or to request its deletion at any time. For more information, you can
          view your rights under our privacy policy.
        </Text>
      </VStack>

      <VStack spacing={6}>
        {map(
          DATA_REQUESTS,
          ({ type, path, ...requestProps }: RequestFormProps) => (
            <DataRequestBanner {...requestProps} key={type} path={path} />
          )
        )}
      </VStack>
    </>
  );
}
