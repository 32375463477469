import { apiClient } from "utils/api-client/index";
import { ResponseInterceptor } from "utils/api-client/interceptors/index";
import { GenericResponse } from "types/api";

let csrfRequestSingleton: Promise<GenericResponse<unknown>> | null;

export const responseCsrfToken: ResponseInterceptor = async ({
  response,
  path,
  config,
}) => {
  if (response.status === 419) {
    if (!csrfRequestSingleton) {
      csrfRequestSingleton = apiClient.get("/csrf");
    }

    await csrfRequestSingleton;
    csrfRequestSingleton = null;

    return (await apiClient.http(path, config)).response;
  } else {
    return response;
  }
};
