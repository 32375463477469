import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { inputAnatomy } from "@chakra-ui/anatomy";

export const Input = createMultiStyleConfigHelpers(
  inputAnatomy.keys
).defineMultiStyleConfig({
  baseStyle: {
    field: {
      background: "solid",
      backgroundColor: "white",
    },
  },
});
