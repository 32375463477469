import { chakra, ChakraProps } from "@chakra-ui/react";

export function ProfilesIcon(props: ChakraProps) {
  return (
    <chakra.svg
      width={"32"}
      height={"32"}
      viewBox={"0 0 32 32"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...props}
    >
      <chakra.path
        fill-rule={"evenodd"}
        clip-rule={"evenodd"}
        d={
          "M7.5 21.9999C5.09608 21.9999 3 24.093 3 26.7977V27.4999C3 28.3283 2.32843 28.9999 1.5 28.9999C0.671573 28.9999 0 28.3283 0 27.4999V26.7977C0 22.5748 3.30392 18.9999 7.5 18.9999H13.5C17.6961 18.9999 21 22.5748 21 26.7977V27.4999C21 28.3283 20.3284 28.9999 19.5 28.9999C18.6716 28.9999 18 28.3283 18 27.4999V26.7977C18 24.093 15.9039 21.9999 13.5 21.9999H7.5Z"
        }
        fill={"white"}
      />
      <chakra.path
        fill-rule={"evenodd"}
        clip-rule={"evenodd"}
        d={
          "M20 19.4999C20 18.6715 20.6716 17.9999 21.5 17.9999H26.0817C29.4521 17.9999 32 20.7403 32 23.9779V24.4999C32 25.3283 31.3284 25.9999 30.5 25.9999C29.6716 25.9999 29 25.3283 29 24.4999V23.9779C29 22.3195 27.7187 20.9999 26.0817 20.9999H21.5C20.6716 20.9999 20 20.3283 20 19.4999Z"
        }
        fill={"white"}
      />
      <chakra.path
        fill-rule={"evenodd"}
        clip-rule={"evenodd"}
        d={
          "M10.5 6.8999C8.567 6.8999 7 8.46691 7 10.3999C7 12.3329 8.567 13.8999 10.5 13.8999C12.433 13.8999 14 12.3329 14 10.3999C14 8.46691 12.433 6.8999 10.5 6.8999ZM4 10.3999C4 6.81005 6.91015 3.8999 10.5 3.8999C14.0899 3.8999 17 6.81005 17 10.3999C17 13.9898 14.0899 16.8999 10.5 16.8999C6.91015 16.8999 4 13.9898 4 10.3999Z"
        }
        fill={"white"}
      />
      <chakra.path
        fill-rule={"evenodd"}
        clip-rule={"evenodd"}
        d={
          "M23.8 9.99988C22.9716 9.99988 22.3 10.6715 22.3 11.4999C22.3 12.3283 22.9716 12.9999 23.8 12.9999C24.6285 12.9999 25.3 12.3283 25.3 11.4999C25.3 10.6715 24.6285 9.99988 23.8 9.99988ZM19.3 11.4999C19.3 9.0146 21.3148 6.99988 23.8 6.99988C26.2853 6.99988 28.3 9.0146 28.3 11.4999C28.3 13.9852 26.2853 15.9999 23.8 15.9999C21.3148 15.9999 19.3 13.9852 19.3 11.4999Z"
        }
        fill={"white"}
      />
    </chakra.svg>
  );
}
