import { Flex, Tag, Text } from "@chakra-ui/react";

export function FullNameCell({
  row,
}: {
  row: { first_name: string; last_name: string; email: string; status: string };
}) {
  return (
    <Flex gap={"2"}>
      <Text fontSize={"md"} fontWeight={"medium"}>
        {row.first_name} {row.last_name}
      </Text>
      {row.status === "pending" ? (
        <Tag size={"sm"} bg={"gray.100"} color={"gray.600"}>
          invitation sent
        </Tag>
      ) : null}
    </Flex>
  );
}
