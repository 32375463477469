import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { inputAnatomy } from "@chakra-ui/anatomy";
import { layerStyles } from "features/privacy-app/theme/layer-styles";

export const Input = createMultiStyleConfigHelpers(
  inputAnatomy.keys
).defineMultiStyleConfig({
  variants: {
    outline: {
      field: {
        ...layerStyles["input-container"],
      },
    },
  },
  sizes: {
    md: {
      field: {
        px: "2.5",
      },
    },
  },
});
