import { useToast } from "@chakra-ui/react";
import { useCallback, useState } from "react";

interface Props {
  successMessage?: string;
  disableToast?: boolean;
}

export function useClipboard({ successMessage, disableToast }: Props) {
  const [isCopied, setIsCopied] = useState(false);
  const toast = useToast();
  const copyText = useCallback(async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      setIsCopied(true);
      // Same with toast duration
      setTimeout(() => setIsCopied(false), 5000);
      !disableToast &&
        toast({
          status: "success",
          title: successMessage ?? "Copied",
        });
    } catch (error) {
      toast({
        status: "error",
        title: error ? error.toString() : "Copy failed",
      });
    }
  }, []);

  return { copyText, isCopied };
}
