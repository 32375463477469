export const Heading = {
  baseStyle: {
    color: "gray.700",
  },
  sizes: {
    lg: {
      fontWeight: 800,
    },
    xs: {
      textTransform: "uppercase",
      color: "gray.400",
      letterSpacing: "0.5px",
      fontSize: "xs",
      mb: 4,
    },
  },
};
