export function Empty() {
  return (
    <svg
      width={"112"}
      height={"112"}
      viewBox={"0 0 112 112"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <path
        d={
          "M6.06464 35.4716C-4.44161 58.255 -3.0653 94.8765 24.3976 103.689C47.069 110.97 92.2973 107.026 105.088 83.984C139.538 18.005 35.5202 -28.3949 6.06464 35.4716Z"
        }
        fill={"#E7EDFD"}
      />
      <g filter={"url(#filter0_d_1560_425058)"}>
        <path
          d={
            "M79.2947 79.3777C81.8169 77.6406 83.6662 34.6229 80.4442 33.3268C77.2221 32.0307 24.1771 30.6456 22.1973 33.166C20.2175 35.6865 19.1771 77.945 20.771 79.378C22.3737 80.8096 76.7676 81.1114 79.2947 79.3777Z"
          }
          fill={"white"}
        />
      </g>
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M37.2011 74.798C43.6698 74.7783 63.651 74.7093 73.4115 74.5319C74.7979 74.5056 75.8821 74.2395 75.8788 73.9241L75.8131 70.008C75.8098 69.7124 74.8341 69.4692 73.5364 69.443C66.6274 69.2984 45.7396 68.9206 36.3798 69.3477C35.2365 69.4003 34.356 69.6138 34.2213 69.8733C33.8107 70.6684 33.1766 72.4917 34.701 74.338C34.9244 74.6107 35.9757 74.8013 37.2011 74.798Z"
        }
        stroke={"#DBDCDE"}
        strokeMiterlimit={"10"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
        strokeDasharray={"2 3"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M37.8984 64.5987C44.2455 64.6381 63.8521 64.7465 73.4288 64.6513C74.7889 64.6381 75.8599 64.372 75.8599 64.0534L75.8796 60.0354C75.8796 59.7332 74.9301 59.4769 73.6587 59.4375C66.8812 59.2273 46.4007 58.6523 37.2052 59.0039C36.0816 59.0466 35.2143 59.2568 35.0763 59.5262C34.6558 60.3377 33.9954 62.2037 35.4541 64.1092C35.6709 64.3885 36.6992 64.5921 37.8984 64.5987Z"
        }
        stroke={"#DBDCDE"}
        strokeMiterlimit={"10"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
        strokeDasharray={"2 3"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M73.2684 36.9819C66.7602 36.9655 46.6608 36.9326 36.8411 37.1199C35.4449 37.1462 34.3541 37.5207 34.3574 37.9675L34.3969 43.5426C34.4001 43.9631 35.3792 44.3147 36.6834 44.3607C43.6351 44.6103 62.5508 44.7418 74.0602 44.7418C75.21 44.6761 76.097 44.3771 76.235 44.0059C76.6555 42.8757 77.3027 40.2836 75.7816 37.6488C75.5582 37.2612 74.5004 36.9852 73.2684 36.9819Z"
        }
        fill={"#0F2C65"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M72.106 47.8449C65.91 47.8318 46.783 47.8088 37.4396 47.9796C36.1124 48.0026 35.0709 48.3344 35.0742 48.732L35.1104 53.6862C35.1137 54.0607 36.0434 54.3728 37.2852 54.4122C43.8985 54.6323 63.8895 55.227 72.8551 54.7408C73.9491 54.6816 74.7934 54.4155 74.9248 54.087C75.3223 53.0817 75.9432 50.7787 74.4944 48.4363C74.2842 48.0913 73.2789 47.8449 72.106 47.8449Z"
        }
        stroke={"#DBDCDE"}
        strokeMiterlimit={"10"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
        strokeDasharray={"2 3"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M31.5388 36.9987C30.4263 36.9743 27.0017 36.915 25.3277 37.0684C25.0906 37.0928 24.9092 37.4311 24.9162 37.8391L24.9964 42.9272C25.0034 43.3109 25.1743 43.6317 25.3975 43.677C26.5867 43.9142 30.1752 44.5663 31.7759 44.0816C31.9712 44.0223 32.1177 43.7538 32.1351 43.4155C32.1909 42.3832 32.2676 40.0222 31.9747 37.6159C31.9328 37.2602 31.748 37.0057 31.5388 36.9987Z"
        }
        fill={"#D871A4"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M25.2187 48.6986C25.1957 49.7433 25.1366 52.9727 25.2844 54.5464C25.3042 54.7698 25.6228 54.9406 26.0072 54.934L30.8004 54.8585C31.1618 54.8519 31.4641 54.6909 31.5068 54.4807C31.7302 53.3604 32.3445 49.9798 31.8879 48.4719C31.832 48.2879 31.5791 48.1499 31.2604 48.1335C30.2879 48.0809 28.0638 48.0086 25.7969 48.2846C25.4618 48.3273 25.222 48.5014 25.2187 48.6986Z"
        }
        fill={"#DBDCDE"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M25.8378 64.6561C26.8826 64.6791 30.112 64.7382 31.6857 64.5904C31.9091 64.5707 32.0799 64.252 32.0733 63.8676L31.9978 59.0744C31.9912 58.713 31.8302 58.4108 31.62 58.368C30.503 58.1446 27.1224 57.5303 25.6144 57.987C25.4305 58.0428 25.2925 58.2958 25.2761 58.6144C25.2235 59.5869 25.1512 61.811 25.4272 64.0779C25.4666 64.413 25.6407 64.6528 25.8378 64.6561Z"
        }
        fill={"#DBDCDE"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M31.8807 74.7884C31.9037 73.7437 31.9628 70.5142 31.815 68.9406C31.7953 68.7172 31.4766 68.5463 31.0922 68.5529L26.299 68.6252C25.9376 68.6318 25.6354 68.7927 25.5927 69.003C25.3693 70.1233 24.7549 73.5039 25.2116 75.0118C25.2674 75.1958 25.5204 75.3338 25.8391 75.3502C26.8115 75.4027 29.0356 75.475 31.3025 75.1991C31.6376 75.1564 31.8774 74.9855 31.8807 74.7884Z"
        }
        fill={"#DBDCDE"}
      />
      <defs>
        <filter
          id={"filter0_d_1560_425058"}
          x={"19.9922"}
          y={"31.7601"}
          width={"64.1696"}
          height={"51.8075"}
          filterUnits={"userSpaceOnUse"}
          colorInterpolationFilters={"sRGB"}
        >
          <feFlood floodOpacity={"0"} result={"BackgroundImageFix"} />
          <feColorMatrix
            in={"SourceAlpha"}
            type={"matrix"}
            values={"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"}
            result={"hardAlpha"}
          />
          <feOffset dx={"2"} dy={"3"} />
          <feColorMatrix
            type={"matrix"}
            values={"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"}
          />
          <feBlend
            mode={"normal"}
            in2={"BackgroundImageFix"}
            result={"effect1_dropShadow_1560_425058"}
          />
          <feBlend
            mode={"normal"}
            in={"SourceGraphic"}
            in2={"effect1_dropShadow_1560_425058"}
            result={"shape"}
          />
        </filter>
      </defs>
    </svg>
  );
}
