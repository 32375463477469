import { Box, Center, Spinner, VStack } from "@chakra-ui/react";
import { HistoryItem } from "features/customer-dashboard/components/history-item";
import { PageCard } from "features/customer-dashboard/patterns/page-card";
import { useCustomer } from "features/customer-dashboard/api/customer";
import { Empty } from "components/empty";
import { Pagination } from "components/pagination";
import { useState } from "react";

export function History() {
  const { getActivityHistory } = useCustomer();
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } = getActivityHistory(page);

  return (
    <PageCard
      heading={"Your activity history"}
      summary={
        "View any past changes to your communication preferences and any data requests made."
      }
    >
      {isLoading || isFetching ? (
        <Center py={8}>
          <Spinner size={"lg"} />
        </Center>
      ) : data?.data.length === 0 ? (
        <Empty
          emptyMessage={"No history to show yet"}
          emptyDesc={"Once any activity happens, it will appear here "}
        />
      ) : (
        <>
          <VStack spacing={[6, 8]} align={"stretch"} mb={[6, 8]}>
            {data?.data.map((activity) => (
              <HistoryItem key={activity.id} {...activity} />
            ))}
          </VStack>
          {data?.meta && (
            <Box p={[0, 4]}>
              <Pagination {...data.meta} setPage={setPage} />
            </Box>
          )}
        </>
      )}
    </PageCard>
  );
}
