import { chakra, ChakraProps } from "@chakra-ui/react";

export function PrivacyIcon(props: ChakraProps) {
  return (
    <chakra.svg
      width={"18"}
      height={"20"}
      viewBox={"0 0 18 20"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...props}
    >
      <chakra.path
        d={
          "M9 13.75C9.86294 13.75 10.5625 13.0504 10.5625 12.1875C10.5625 11.3246 9.86294 10.625 9 10.625C8.13706 10.625 7.4375 11.3246 7.4375 12.1875C7.4375 13.0504 8.13706 13.75 9 13.75Z"
        }
        fill={"white"}
      />
      <chakra.path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M9 0C6.38118 0 4 1.9178 4 4.58333V5H3.64286C1.802 5 0.25 6.45897 0.25 8.33333V16.6667C0.25 18.541 1.802 20 3.64286 20H14.3571C16.198 20 17.75 18.541 17.75 16.6667V8.33333C17.75 6.45898 16.198 5 14.3571 5H14V4.58333C14 1.9178 11.6188 0 9 0ZM3.64286 7.5H14.3571C14.8841 7.5 15.25 7.90561 15.25 8.33333V16.6667C15.25 17.0944 14.8841 17.5 14.3571 17.5H3.64286C3.11586 17.5 2.75 17.0944 2.75 16.6667V8.33333C2.75 7.90561 3.11586 7.5 3.64286 7.5ZM6.5 4.58333V5H11.5V4.58333C11.5 3.5672 10.5231 2.5 9 2.5C7.47694 2.5 6.5 3.5672 6.5 4.58333Z"
        }
        fill={"white"}
      />
    </chakra.svg>
  );
}
