import {
  Box,
  Button,
  Input,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { Panel } from "features/privacy-app/components/panel";
import { PageHeader } from "features/privacy-app/patterns/layout";
import { Profile, useProfile } from "features/privacy-app/api/profiles";
import { useState } from "react";
import { Table, TableColumn } from "components/table/index";
import { useForm } from "react-hook-form";
import { FormField } from "components/form-field";
import { Form } from "components/form";
import { useModal } from "@ebay/nice-modal-react";
import { ProfileModal } from "features/privacy-app/screens/profiles/modals/profile";

const listFormat = new Intl.ListFormat("en-GB", {
  style: "long",
  type: "conjunction",
});

const COLUMNS: TableColumn<Profile>[] = [
  {
    label: "ID",
    id: "id",
  },
  {
    label: "Names",
    id: "contactNames",
    formatValue: (_, row) => {
      const names = row.contacts.map((c) => c.names).flat(1);
      return names
        ? listFormat.format(names.map((n) => `${n.first_name} ${n.last_name}`))
        : "";
    },
  },
  {
    label: "Contacts",
    id: "contacts",
    formatValue: (v) => String(v.length),
  },
];

export function Profiles() {
  const { searchProfilesByEmail, getProfileStats } = useProfile();
  const [email, setEmail] = useState<string>();
  const {
    data: profiles,
    isFetching,
    isLoading,
  } = searchProfilesByEmail(email);
  const { data: stats, isLoading: statsIsLoading } = getProfileStats();
  const profileModal = useModal(ProfileModal);

  const form = useForm<{ emailPartial: string }>();

  return (
    <Box>
      <PageHeader
        title={"Profiles"}
        description={
          "See a summary of current loaded profiles and search for a specific profile."
        }
      />
      <Panel title={"Stats"} isLoading={statsIsLoading} height={"121px"}>
        <Box
          display={"flex"}
          flexDir={"row"}
          gap={4}
          justifyContent={"flex-start"}
        >
          <Stat>
            <StatLabel>Total Profiles</StatLabel>
            <StatNumber>{stats?.total_profiles}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Total Contacts</StatLabel>
            <StatNumber>{stats?.total_contacts}</StatNumber>
          </Stat>
        </Box>
      </Panel>
      <Panel title={"Search for a profile"}>
        <Form
          form={form}
          promptIfUnsavedChanges={false}
          onSubmit={(v) => setEmail(v.emailPartial)}
        >
          <Box w={"33.3%"} display={"flex"} flexDir={"row"} gap={"2"}>
            <FormField
              name={"emailPartial"}
              Input={Input}
              inputProps={{
                placeholder: "Search by full email or email prefix",
              }}
            />
            <Button type={"submit"}>Search</Button>
          </Box>
        </Form>
        {email ? (
          <Box mt={"4"}>
            <Table<Profile>
              isLoading={isLoading}
              isFetching={isFetching}
              columns={COLUMNS}
              onRowClick={(row) => profileModal.show({ profile: row })}
              rows={profiles || []}
              emptyProps={{
                emptyMessage: "No profiles",
                emptyDesc:
                  "No profiles found that match that email or email prefix",
              }}
            />
          </Box>
        ) : null}
      </Panel>
    </Box>
  );
}
