import { ReactNode } from "react";
import { Box, BoxProps, Text } from "@chakra-ui/react";

interface LabelValueProps extends BoxProps {
  label: string;
  value?: ReactNode;
}

export function LabelValue({ label, value, ...rest }: LabelValueProps) {
  return (
    <Box display={"flex"} flexDir={"column"} gap={"1"} {...rest}>
      <Text fontSize={"sm"} fontWeight={"semibold"}>
        {label}
      </Text>
      <Text>
        {value === "" ||
        value === undefined ||
        value === null ||
        (Array.isArray(value) && value.length === 0)
          ? "–"
          : value}
      </Text>
    </Box>
  );
}
