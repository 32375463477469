import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { accordionAnatomy } from "@chakra-ui/anatomy";

export const Accordion = createMultiStyleConfigHelpers(
  accordionAnatomy.keys
).defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderWidth: "1px",
      borderColor: "gray.200",

      borderRadius: "md",
      bg: "white",
      px: 1,
    },
    button: {
      py: 4,
      fontWeight: 500,
      _hover: {
        bg: null,
      },
    },
  },
});
