import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { useNavigate } from "@tanstack/react-location";

interface AuthContextType {
  user?: User;
  loading: boolean;
  error?: any;
  login: () => void;
  logout: () => void;
}

interface User {
  id: number;
  name: string;
  email: string;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | undefined>();
  const [error] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  async function login() {
    setLoading(true);

    await new Promise((r) => setTimeout(r, 1000));

    setUser({
      id: 1,
      email: "alex.babkov@realevo.com",
      name: "Alex Babkox",
    } as User);
    setLoading(false);
    navigate({ to: "/home" });
  }

  async function logout() {
    setUser(undefined);
  }

  const contextValue = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      logout,
    }),
    [user, loading, error]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  return useContext(AuthContext);
}
export { useAuth, AuthProvider };
