import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";
import { CellProps } from "components/table";
import { DATA_SOURCES_STATUS_TYPES } from "features/privacy-app/api/data-sources";
import { useMemo } from "react";

export function StatusCell({ value }: CellProps) {
  const status = DATA_SOURCES_STATUS_TYPES.find((ds) => ds.key === value);

  const { label, tooltip } = useMemo(() => {
    switch (value) {
      case "not_configured":
        return {
          label: status?.label,
          tooltip:
            "Data connection is pending configuration. It needs to be connected in your data source’s external platform settings",
        };
      case "feeding":
        return {
          label: status?.label,
          tooltip: "Data connection is actively feeding data",
        };
      case "dormant":
        return {
          label: status?.label,
          tooltip:
            "Data connection has not had any activity in the past 30 days",
        };
      default:
        return { label: "", tooltip: "" };
    }
  }, [value]);

  return (
    <>
      {label}{" "}
      <Tooltip label={tooltip} hasArrow textAlign={"center"}>
        <InfoOutlineIcon />
      </Tooltip>
    </>
  );
}
