import { Text } from "@chakra-ui/react";
import { ConsentType } from "features/privacy-app/api/consent-type";

export function ChangedByCell({ row }: { row: ConsentType }) {
  return (
    <Text>
      {row.changed_by.first_name} {row.changed_by.last_name}
    </Text>
  );
}
