import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Text,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link } from "@tanstack/react-location";

interface DataRequestBannerProps {
  title: string;
  description: string;
  path: string;
}

export function DataRequestBanner({
  title,
  description,
  path,
}: DataRequestBannerProps) {
  return (
    <Card
      direction={["column", "row"]}
      align={["flex-start", "center"]}
      w={"full"}
    >
      <CardBody py={6}>
        <Heading size={"sm"} mb={2}>
          {title}
        </Heading>
        <Text>{description}</Text>
      </CardBody>
      <CardFooter pt={[0, 5]}>
        <Link to={path} search>
          <Button rightIcon={<ChevronRightIcon />}>Request</Button>
        </Link>
      </CardFooter>
    </Card>
  );
}
