import { FieldValues } from "react-hook-form";

export const getModifiedFields = <T extends FieldValues>(
  allFields: T,
  dirtyFields: Partial<Record<keyof T, boolean>>
): T => {
  const modifiedFields = Object.keys(dirtyFields).reduce(
    (acc, currentField) => {
      return {
        ...acc,
        [currentField]: allFields[currentField],
      };
    },
    {} as T
  );

  return modifiedFields;
};
