import {
  Avatar,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useAuthentication } from "features/privacy-app/api/authentication";

export function HeaderNav() {
  const { getAccessibleSilos, getUser, getCurrentSilo, switchToSilo } =
    useAuthentication();
  const { data: silos } = getAccessibleSilos();
  const { data: user } = getUser();
  const currentSiloId = getCurrentSilo();
  const currentSilo = silos?.find((s) => s.id === currentSiloId);

  return (
    <Flex
      p={6}
      h={"72px"}
      borderBottom={"1px"}
      borderBottomColor={"gray.200"}
      align={"center"}
    >
      {silos ? (
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            colorScheme={"gray"}
            bg={"gray.50"}
            fontSize={"lg"}
          >
            {currentSilo?.display_name}
          </MenuButton>
          <MenuList>
            {silos?.map((item) => (
              <MenuItem key={item.id} onClick={() => switchToSilo(item.id)}>
                {item.display_name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      ) : null}

      <Spacer />

      <Menu>
        <MenuButton>
          <Avatar
            h={10}
            w={10}
            name={user?.first_name + " " + user?.last_name}
            bg={"brand.900"}
            fontSize={"15px"}
          />
        </MenuButton>
        <MenuList>
          <a
            href={
              import.meta.env.VITE_AUTHENTICATION_SERVICE_FRONTEND_URL +
              "/profile"
            }
          >
            <MenuItem>Profile</MenuItem>
          </a>
          <a
            href={
              import.meta.env.VITE_AUTHENTICATION_SERVICE_FRONTEND_URL +
              "?app_id=single_view&logout=1"
            }
          >
            <MenuItem>Logout</MenuItem>
          </a>
        </MenuList>
      </Menu>
    </Flex>
  );
}
