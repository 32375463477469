import { Table } from "components/table";
import { useDataRequest } from "features/privacy-app/api/data-request";
import { DataRequestTypeCell } from "features/privacy-app/components/table/data-request-type-cell";
import { DateCell } from "features/privacy-app/components/table/date-cell";
import { NameEmailCell } from "features/privacy-app/components/table/name-email-cell";

interface DataRequestsTableProps {
  type: "active" | "archived";
}

export function DataRequestsTable({ type }: DataRequestsTableProps) {
  const { getDataRequests } = useDataRequest();
  const { data: dataRequests, isLoading } = getDataRequests({
    archived: type === "active" ? 0 : 1,
  });

  return (
    <Table
      columns={[
        {
          label: "Name",
          id: "email",
          Cell: NameEmailCell,
        },
        {
          label: "Type",
          id: "type",
          Cell: DataRequestTypeCell,
        },
        {
          label: "Requested date",
          id: "created_at",
          Cell: DateCell,
        },
        {
          label: "Notes",
          id: "notes",
        },
      ]}
      rows={dataRequests?.data ?? []}
      isLoading={isLoading}
      emptyProps={{
        emptyMessage: `No ${
          type === "active" ? "active" : "archived"
        } data requests`,
        emptyDesc:
          type === "active"
            ? "Any data requests from customers will appear here"
            : "Any data requests older than 90 days will automatically appear here",
      }}
    />
  );
}
