import { chakra, ChakraProps } from "@chakra-ui/react";

export function DataRequestsIcon(props: ChakraProps) {
  return (
    <chakra.svg
      width={"16"}
      height={"16"}
      viewBox={"0 0 16 16"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...props}
    >
      <chakra.path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M6.68062 2L6 2.85078V3H10V2.85057L9.32007 2H6.68062ZM11.9823 3.5C11.8609 4.34792 11.1314 5 10.25 5H5.75C4.86859 5 4.13909 4.34792 4.01773 3.5H2.75C2.47552 3.5 2.26119 3.58909 2.13408 3.68997C2.00878 3.78942 2 3.86644 2 3.88889V13.6111C2 13.6336 2.00878 13.7106 2.13408 13.81C2.26119 13.9109 2.47552 14 2.75 14H13.25C13.5245 14 13.7388 13.9109 13.8659 13.81C13.9912 13.7106 14 13.6336 14 13.6111V3.88889C14 3.86644 13.9912 3.78942 13.8659 3.68997C13.7388 3.58909 13.5245 3.5 13.25 3.5H11.9823ZM4.51923 1.50018L5.19477 0.655752C5.5268 0.241587 6.02891 0 6.56075 0H9.44C9.97184 0 10.4739 0.241588 10.806 0.655752L10.8069 0.656848L11.481 1.50018C11.4873 1.50006 11.4937 1.5 11.5 1.5H13.25C14.5413 1.5 16 2.36609 16 3.88889V13.6111C16 15.1339 14.5413 16 13.25 16H2.75C1.4587 16 0 15.1339 0 13.6111V3.88889C0 2.36609 1.4587 1.5 2.75 1.5H4.5C4.50642 1.5 4.51283 1.50006 4.51923 1.50018ZM6.72135 5.80743C7.10384 6.20581 7.09096 6.83885 6.69257 7.22135L5.44248 8.42158C5.05551 8.79312 4.44431 8.79312 4.05733 8.42158L3.30743 7.70158C2.90904 7.31909 2.89616 6.68605 3.27865 6.28766C3.66115 5.88927 4.29419 5.87639 4.69257 6.25889L4.74991 6.31394L5.30743 5.77865C5.70581 5.39616 6.33885 5.40904 6.72135 5.80743ZM8 7C8 6.44772 8.44772 6 9 6H11.5C12.0523 6 12.5 6.44772 12.5 7C12.5 7.55228 12.0523 8 11.5 8H9C8.44772 8 8 7.55228 8 7ZM6.72135 8.80743C7.10384 9.20581 7.09096 9.83885 6.69257 10.2213L5.44248 11.4216C5.05551 11.7931 4.44431 11.7931 4.05733 11.4216L3.30743 10.7016C2.90904 10.3191 2.89616 9.68605 3.27865 9.28766C3.66115 8.88927 4.29419 8.87639 4.69257 9.25889L4.74991 9.31394L5.30743 8.77865C5.70581 8.39616 6.33885 8.40904 6.72135 8.80743ZM8 9.5C8 8.94772 8.44772 8.5 9 8.5H11.5C12.0523 8.5 12.5 8.94772 12.5 9.5C12.5 10.0523 12.0523 10.5 11.5 10.5H9C8.44772 10.5 8 10.0523 8 9.5ZM8 12C8 11.4477 8.44772 11 9 11H11.5C12.0523 11 12.5 11.4477 12.5 12C12.5 12.5523 12.0523 13 11.5 13H9C8.44772 13 8 12.5523 8 12Z"
        }
        fill={"white"}
      />
    </chakra.svg>
  );
}
