import { createStandaloneToast, ToastProviderProps } from "@chakra-ui/react";
import { privacyAppTheme } from "features/privacy-app/theme";

export const toastGlobalPropsOverride: ToastProviderProps = {
  defaultOptions: {
    position: "top-right",
    variant: "subtle",
    isClosable: true,
  },
};

export const { toast } = createStandaloneToast({
  theme: privacyAppTheme,
  defaultOptions: toastGlobalPropsOverride.defaultOptions,
});
