import { chakra, ChakraProps } from "@chakra-ui/react";

export function DataSourcesIcon(props: ChakraProps) {
  return (
    <chakra.svg
      width={"20"}
      height={"20"}
      viewBox={"0 0 20 20"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...props}
    >
      <chakra.path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M9.5076 0.101069C9.82204 -0.0336896 10.178 -0.0336896 10.4924 0.101069L19.2424 3.85107C19.702 4.04804 20 4.49997 20 5V7.5C20 8.19036 19.4404 8.75 18.75 8.75H17.5V13.75H18.125C18.6986 13.75 19.1986 14.1404 19.3377 14.6968L19.9627 17.1968C19.9875 17.296 20 17.3978 20 17.5V18.75C20 19.4404 19.4404 20 18.75 20H1.25C0.559644 20 0 19.4404 0 18.75V17.5C0 17.3978 0.0125342 17.296 0.0373219 17.1968L0.662322 14.6968C0.801437 14.1404 1.30142 13.75 1.875 13.75H2.5V8.75H1.25C0.559644 8.75 0 8.19036 0 7.5V5C0 4.49997 0.297996 4.04804 0.757601 3.85107L9.5076 0.101069ZM5 8.75V13.75H6.25V8.75H5ZM7.50881 6.25C7.50587 6.24998 7.50294 6.24997 7.5 6.24997C7.49706 6.24997 7.49412 6.24998 7.49119 6.25H3.75881C3.75587 6.24998 3.75294 6.24997 3.75 6.24997C3.74706 6.24997 3.74412 6.24998 3.74119 6.25H2.5V5.82425L10 2.60996L17.5 5.82425V6.25H16.2588C16.2559 6.24998 16.2529 6.24997 16.25 6.24997C16.2471 6.24997 16.2441 6.24998 16.2412 6.25H12.5088C12.5059 6.24998 12.5029 6.24997 12.5 6.24997C12.4971 6.24997 12.4941 6.24998 12.4912 6.25H7.50881ZM8.75 8.75V13.75H11.25V8.75H8.75ZM13.75 8.75V13.75H15V8.75H13.75ZM17.149 16.25H2.85097L2.53847 17.5H17.4615L17.149 16.25Z"
        }
        fill={"white"}
      />
    </chakra.svg>
  );
}
