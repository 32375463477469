import { MenuList } from "@chakra-ui/react";

MenuList.defaultProps = {
  ...MenuList.defaultProps,
  motionProps: {
    variants: {
      enter: {
        visibility: "visible",
        opacity: 1,
        scale: 1,
        translateY: 0,
        transition: {
          duration: 0,
        },
      },
      exit: {
        transitionEnd: {
          visibility: "hidden",
        },
        opacity: 0,
        scale: 1,
        translateY: -5,
        transition: {
          duration: 0,
        },
      },
    },
  },
};
