import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex, Text } from "@chakra-ui/react";
import { Empty as EmptyIcon } from "components/icons";
import { ReactNode } from "react";

export interface EmptyProps {
  emptyMessage: string;
  emptyDesc: string;
  addLabel?: string;
  onAdd?: () => void;
  icon?: ReactNode;
}

export function Empty({
  emptyMessage,
  emptyDesc,
  addLabel,
  onAdd,
  icon = <EmptyIcon />,
}: EmptyProps) {
  return (
    <Flex direction={"column"} align={"center"} justify={"center"} h={300}>
      {icon}
      <Text fontSize={"lg"} fontWeight={"bold"} mt={6}>
        {emptyMessage}
      </Text>
      <Text fontWeight={"medium"} mt={2}>
        {emptyDesc}
      </Text>
      {addLabel && (
        <Button leftIcon={<AddIcon />} mt={5} onClick={onAdd}>
          {addLabel}
        </Button>
      )}
    </Flex>
  );
}
