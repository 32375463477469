import { Container, Heading, VStack } from "@chakra-ui/react";
import { ErrorContent } from "components/error/error-content";
import { FetchClientError } from "errors/fetch-error";
import { StatusMessage } from "features/customer-dashboard/components/status-message";
import { useCustomer } from "features/customer-dashboard/api/customer";
import { useLocation } from "@tanstack/react-location";

export function ErrorScreen({ error }: { error: Error }) {
  if (error instanceof FetchClientError && error.message === "Expired") {
    const {
      history,
      current: { href, search },
    } = useLocation();

    const { resendInviteLink } = useCustomer();
    const { mutate: resendInviteLinkMutate, isLoading } = resendInviteLink({
      onSuccess: () => {
        history.push({ pathname: `${href}&resent=true` });
      },
    });

    return (
      <Container maxW={"2xl"} pt={"32"}>
        <VStack spacing={"4"} w={"100%"}>
          {search?.resent ? (
            <StatusMessage
              heading={"New link sent"}
              message={
                "We have sent a new link to your email to access your data\n" +
                "privacy preferences."
              }
            />
          ) : (
            <StatusMessage
              status={"error"}
              heading={"This link has expired"}
              message={
                "For security reasons, we have sent you a new link to access your subscription and data privacy preferences."
              }
              action={{
                text: "Didn't receive an email?",
                isLoading,
                onClick: resendInviteLinkMutate,
              }}
            />
          )}
        </VStack>
      </Container>
    );
  }

  if (error instanceof FetchClientError && error.response?.status === 401) {
    return (
      <Container maxW={"2xl"} pt={"32"}>
        <VStack spacing={"4"} w={"100%"}>
          <StatusMessage
            status={"error"}
            heading={"Unauthorised"}
            message={
              "The link you have followed is not valid for any known customer profiles"
            }
          />
        </VStack>
      </Container>
    );
  }

  return (
    <Container maxW={"2xl"} pt={"32"}>
      <VStack spacing={"4"} w={"100%"}>
        <Heading>An error occurred</Heading>
        <ErrorContent error={error} />
      </VStack>
    </Container>
  );
}
