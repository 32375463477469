import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { ErrorContent } from "components/error/error-content";
import { AppError } from "errors";
import { FetchClientError, FetchServerError } from "errors/fetch-error";

interface ErrorModalProps {
  error: Error | AppError | FetchServerError | FetchClientError;
}

export const ErrorModal = createModal<ErrorModalProps>(({ error }) => {
  const modal = useModal();

  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.hide}
      onCloseComplete={modal.remove}
    >
      <ModalOverlay />
      <ModalContent w={600}>
        <ModalHeader>Error</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ErrorContent error={error as Error} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={modal.hide}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
