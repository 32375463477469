import { SVGProps } from "react";

export function Success(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={"113"}
      height={"112"}
      viewBox={"0 0 113 112"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...props}
    >
      <g clipPath={"url(#clip0_47_5090)"}>
        <path
          d={
            "M6.56464 35.4716C-3.94161 58.255 -2.5653 94.8765 24.8976 103.689C47.569 110.97 92.7973 107.026 105.588 83.984C140.038 18.005 36.0202 -28.3949 6.56464 35.4716Z"
          }
          fill={"#E7EDFD"}
        />
        <path
          d={
            "M84.2082 71.9824C103.627 35.2432 45.0047 9.40523 28.4166 44.9652C22.4941 57.6483 23.2801 78.0357 38.7414 82.9622C51.5035 87.024 77.0017 84.8228 84.2082 71.9824Z"
          }
          fill={"#7BD0A2"}
        />
        <path
          d={
            "M49.9153 69.8013L40.201 61.9122C39.2805 61.1465 39.1543 59.8196 39.8737 58.9164C40.6393 57.9959 41.9663 57.8698 42.8694 58.5891L49.4055 63.9143L67.7808 43.0917C68.5638 42.2176 69.908 42.1378 70.7821 42.9209C71.6562 43.7039 71.736 45.0481 70.953 45.9222L49.9153 69.8013Z"
          }
          fill={"white"}
        />
      </g>
      <defs>
        <clipPath id={"clip0_47_5090"}>
          <rect
            width={"112"}
            height={"112"}
            fill={"white"}
            transform={"translate(0.5)"}
          />
        </clipPath>
      </defs>
    </svg>
  );
}
