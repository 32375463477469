import { theme as baseTheme } from "@chakra-ui/react";

export const colors = {
  brand: baseTheme.colors.teal,
  gray: {
    "50": "hsl(204,45%,98%)",
    "100": "hsl(210,38%,95%)",
    "200": "hsl(214,32%,91%)",
    "300": "hsl(211,25%,84%)",
    "400": "hsl(214,20%,69%)",
    "500": "hsl(220,3%,80%)",
    "600": "hsl(218,17%,35%)",
    "700": "hsl(218,23%,23%)",
    "800": "hsl(210,5%,33%)",
    "900": "hsl(230, 21%, 11%)",
  },
};
