export const layerStyles = {
  "input-container": {
    color: "gray.700",
    borderColor: "gray.200",
    _placeholder: {
      color: "gray.400",
    },
    _hover: {
      borderColor: "gray.300",
    },
    _focus: {
      borderColor: "gray.600",
    },
    _focusVisible: {
      shadow: "outline",
    },
    _disabled: {
      opacity: 1,
      bgColor: "gray.50",
    },
  },
};
