import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemProps,
  MenuList,
  Spinner,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Ellipsis } from "components/icons";
import {
  Dispatch,
  Fragment,
  ReactNode,
  SetStateAction,
  useCallback,
} from "react";
import { get } from "utils/collection";
import { Empty, EmptyProps } from "components/empty";
import { Pagination } from "components/pagination";
import { PaginationMeta } from "types/pagination";

type TableRow<T> = T & {
  id: string;
};

export interface CellProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  row: any;
  value: any;
}

export interface TableColumn<T> {
  label: ReactNode;
  id: string;
  formatValue?: (value: any, row: T) => ReactNode;
  Cell?: (props: CellProps) => JSX.Element;
}

export interface TableAction<T> {
  label: ReactNode;
  id: string;
  onClick: ({ row }: { row: TableRow<T> }) => void;
  addBottomDivider?: boolean;
  isLoading?: boolean;
  menuItemProps?: MenuItemProps;
}

interface TableProps<T = Record<string, unknown>> {
  columns: TableColumn<T>[];
  rows: TableRow<T>[];
  actions?: TableAction<T>[];
  emptyProps?: EmptyProps;
  isLoading?: boolean;
  meta?: PaginationMeta;
  page?: number;
  setPage?: Dispatch<SetStateAction<number>>;
  isFetching?: boolean;
  onRowClick?: (row: T) => void;
}

export function Table<T>({
  columns,
  rows,
  actions,
  isLoading,
  isFetching,
  emptyProps,
  meta,
  setPage,
  onRowClick,
}: TableProps<T>) {
  const getCell = useCallback(
    ({
      column,
      row,
    }: {
      column: TableColumn<T>;
      row: TableRow<T>;
    }): ReactNode => {
      const { Cell, id, formatValue } = column;
      const value = formatValue ? formatValue(get(row, id), row) : get(row, id);
      if (!value) return "–";
      if (Cell) {
        return <Cell row={row} value={value as string} />;
      } else {
        return value;
      }
    },
    []
  );

  if (isLoading) {
    return (
      <Flex w={"full"} h={300} justify={"center"} align={"center"}>
        <Spinner size={"lg"} />
      </Flex>
    );
  }

  if (rows.length === 0 && emptyProps) {
    return <Empty {...emptyProps} />;
  }

  return (
    <>
      <TableContainer>
        <ChakraTable
          variant={"simple"}
          {...(isFetching ? { opacity: 0.3, pointerEvents: "none" } : {})}
        >
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={column.id}>{column.label}</Th>
              ))}
              {actions && <Th />}
            </Tr>
          </Thead>
          <Tbody>
            {rows.map((row) => (
              <Tr
                key={row.id}
                onClick={onRowClick ? () => onRowClick(row) : undefined}
                _hover={
                  onRowClick
                    ? { background: "gray.50", cursor: "pointer" }
                    : undefined
                }
              >
                {columns.map((column) => (
                  <Td key={column.id}>{getCell({ column, row })}</Td>
                ))}
                {actions && (
                  <Td textAlign={"right"}>
                    <Menu isLazy={rows.length > 30}>
                      <MenuButton
                        as={IconButton}
                        aria-label={"options"}
                        icon={<Ellipsis />}
                        variant={"ghost"}
                      />
                      <MenuList>
                        {actions.map((action) => (
                          <Fragment key={action.id}>
                            <MenuItem
                              isDisabled={action.isLoading}
                              onClick={() => action.onClick({ row })}
                              {...(action.menuItemProps
                                ? action.menuItemProps
                                : {})}
                            >
                              {action.isLoading && (
                                <Spinner size={"sm"} mr={2} />
                              )}
                              {action.label}
                            </MenuItem>
                            {action.addBottomDivider && <MenuDivider />}
                          </Fragment>
                        ))}
                      </MenuList>
                    </Menu>
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </ChakraTable>
      </TableContainer>
      {meta && setPage ? (
        <Box p={[0, 4]}>
          <Pagination {...meta} setPage={setPage} />
        </Box>
      ) : null}
    </>
  );
}
