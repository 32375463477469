import { Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { EmailSuccess } from "components/icons/email-success";
import { ReactNode } from "react";
import { PasswordError } from "components/icons/password-error";

export function StatusMessage({
  heading,
  message,
  status = "success",
  action,
}: {
  heading: ReactNode;
  message: ReactNode;
  status?: "success" | "error";
  action?: { text: string; onClick: () => void; isLoading?: boolean };
}) {
  const iconMap = {
    success: EmailSuccess,
    error: PasswordError,
  };

  const Icon = iconMap[status];

  return (
    <Flex
      direction={"column"}
      align={"center"}
      justify={"center"}
      h={"full"}
      pt={"6"}
      pb={"24"}
    >
      <Icon />
      <Text fontWeight={"extrabold"} fontSize={"30px"}>
        {heading}
      </Text>
      <Text mt={4} color={"gray.600"} align={"center"}>
        {message}
      </Text>
      {action?.isLoading ? (
        <Spinner mt={14} />
      ) : (
        action && (
          <Button mt={14} variant={"link"} onClick={action.onClick}>
            {action.text}
          </Button>
        )
      )}
    </Flex>
  );
}
