import { Tag } from "@chakra-ui/react";
import { CellProps } from "components/table";
import { useMemo } from "react";

export function AppTypeCell({ value }: CellProps) {
  const bgColor = useMemo(() => {
    switch (value) {
      case "Rex PM":
        return "red.200";
      case "Rex CRM":
        return "blue.100";
      default:
        return "gray.100";
    }
  }, [value]);

  return <Tag bgColor={bgColor}>{value}</Tag>;
}
