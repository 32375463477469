import {
  Button,
  Flex,
  Input,
  Link,
  SimpleGrid,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { Form } from "components/form";
import { FormField } from "components/form-field";
import { PageCard } from "features/customer-dashboard/patterns/page-card";
import { useForm } from "react-hook-form";
import { RequestFormProps } from "features/customer-dashboard/screens/data-requests/constants";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { InformationCard } from "features/customer-dashboard/patterns/information-card";
import { useNavigate } from "@tanstack/react-location";
import { DataRequest } from "types/data-request";
import { useDataRequest } from "features/customer-dashboard/api/data-request";
import { useConsentPreferences } from "features/customer-dashboard/api/consent-preferences";

export function DataRequestForm({
  title,
  description,
  type,
}: RequestFormProps) {
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const { getConsentPreferences } = useConsentPreferences();
  const { data: consentPreferences } = getConsentPreferences();

  const form = useForm<DataRequest>({
    defaultValues: {
      type,
      profile_id: consentPreferences?.profile_id,
      email: consentPreferences?.guest.email,
    },
  });

  const { storeDataRequest } = useDataRequest();
  const { mutate: mutateDataRequest, isLoading } = storeDataRequest({
    onSuccess: () => setShowSuccess(true),
  });

  const onSendRequest = (values: DataRequest) => {
    mutateDataRequest(values);
  };

  if (showSuccess)
    return (
      <InformationCard
        message={"We have received your request"}
        description={
          "You will receive an email in your inbox once your request has been processed."
        }
        actionText={"Return to Dashboard"}
        onAction={() => navigate({ replace: true, to: "..", search: true })}
      />
    );

  return (
    <PageCard
      heading={title}
      summary={
        <Text color={"gray.600"}>
          {description} You can find information about our response timeframe in
          our{" "}
          <Link color={"blue.600"} href={"/privacy-policy"}>
            {" "}
            Privacy Policy.
          </Link>
        </Text>
      }
      returnUrl={".."}
    >
      <Form form={form} onSubmit={onSendRequest}>
        <VStack spacing={6}>
          <FormField
            Input={Input}
            label={"Email from"}
            name={"email"}
            inputProps={{
              disabled: true,
              placeholder: "Email",
              type: "email",
            }}
            required
          />
          <SimpleGrid columns={[1, null, 2]} spacing={6} w={"full"}>
            <FormField
              Input={Input}
              label={"First name"}
              name={"first_name"}
              inputProps={{
                placeholder: "First name",
                width: "full",
              }}
            />
            <FormField
              Input={Input}
              label={"Last name"}
              name={"last_name"}
              inputProps={{
                placeholder: "Last name",
                width: "full",
              }}
            />
          </SimpleGrid>
          <FormField
            Input={Textarea}
            label={"Additional notes"}
            name={"notes"}
            inputProps={{
              placeholder: "Leave any comments here",
              size: "lg",
            }}
            required
          />

          <Flex w={"full"} justify={"flex-end"}>
            <Button
              rightIcon={<ChevronRightIcon />}
              type={"submit"}
              isLoading={isLoading}
            >
              Request
            </Button>
          </Flex>
        </VStack>
      </Form>
    </PageCard>
  );
}
