import { Box, Button, Input, Text, chakra } from "@chakra-ui/react";
import { Trash, Upload } from "components/icons";
import { ChangeEvent, ReactNode, useMemo, useRef } from "react";
import { ControllerRenderProps, FieldValues } from "react-hook-form";

interface ImageUploadInputProps
  extends Partial<ControllerRenderProps<FieldValues, string>> {
  empty?: ReactNode;
  note?: string;
  isEditing?: boolean;
}

export function ImageUploadInput({
  value,
  onChange,
  empty,
  note,
  isEditing,
  ...rest
}: ImageUploadInputProps) {
  const ref = useRef<HTMLInputElement>(null);

  const preview = useMemo(() => {
    if (!value?.[0]) return "";
    return URL.createObjectURL(value[0]);
  }, [value]);

  return (
    <Box>
      <Box
        h={136}
        p={4}
        borderWidth={"1px"}
        borderRadius={"lg"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {preview ? (
          <chakra.img src={preview} alt={"Logo"} maxH={"full"} />
        ) : (
          empty
        )}
      </Box>
      {note && <Text mt={2}>{note}</Text>}
      {isEditing && (
        <Box display={"flex"} mt={2}>
          <Button
            variant={"outline"}
            colorScheme={"gray"}
            leftIcon={<Trash />}
            mr={3}
            onClick={() => {
              if (ref.current) {
                onChange?.({ target: { ...ref.current, value: "" } });
                // Reset the file input
                ref.current.value = "";
              }
            }}
          >
            Remove
          </Button>
          <Button colorScheme={"gray"} leftIcon={<Upload />} pos={"relative"}>
            <Input
              ref={ref}
              type={"file"}
              accept={".svg,.png,.webp,.jpg,.jpeg"}
              w={"100%"}
              h={"100%"}
              pos={"absolute"}
              top={0}
              left={0}
              opacity={0}
              cursor={"pointer"}
              zIndex={1}
              {...rest}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange?.({
                  ...e,
                  target: { ...e.target, value: e.target.files },
                });
              }}
            />
            Upload
          </Button>
        </Box>
      )}
    </Box>
  );
}
