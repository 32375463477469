import { Button, HStack, Textarea } from "@chakra-ui/react";
import { PageCard } from "features/customer-dashboard/patterns/page-card";
import { Form } from "components/form";
import { useForm } from "react-hook-form";
import { FormField } from "components/form-field";
import { Link } from "@tanstack/react-location";
import { useCustomer } from "features/customer-dashboard/api/customer";
import { StatusMessage } from "features/customer-dashboard/components/status-message";

export function Contact() {
  const form = useForm();
  const { sendEnquiry } = useCustomer();
  const { mutate: sendEnquiryMutate, isLoading, isSuccess } = sendEnquiry();

  return isSuccess ? (
    <PageCard>
      <StatusMessage
        heading={"Message sent"}
        message={
          "Your message has been sent. We will respond to your enquiry as soon as possible."
        }
      />
    </PageCard>
  ) : (
    <PageCard
      heading={"Contact us"}
      summary={
        "If you have any questions or need assistance, please fill a message below."
      }
    >
      <Form
        form={form}
        onSubmit={({ message }) => sendEnquiryMutate({ message })}
      >
        <FormField
          Input={Textarea}
          name={"message"}
          label={"Message"}
          required
        />
        <HStack spacing={"2"} mt={8} justifyContent={"flex-end"}>
          <Button colorScheme={"gray"} as={Link} to={"../preferences"} search>
            Cancel
          </Button>
          <Button type={"submit"} isLoading={isLoading}>
            Submit message
          </Button>
        </HStack>
      </Form>
    </PageCard>
  );
}
