import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { modalAnatomy } from "@chakra-ui/anatomy";
import { textStyles } from "features/privacy-app/theme/text-styles";

export const Modal = createMultiStyleConfigHelpers(
  modalAnatomy.keys
).defineMultiStyleConfig({
  baseStyle: {
    header: {
      px: 10,
      py: 6,
      ...textStyles.h3,
    },
    body: {
      px: 10,
      pt: 0,
      pb: 6,
    },
    footer: {
      px: 10,
      py: 6,
      borderTop: "1px",
      borderTopColor: "gray.200",
      gap: 3,
    },
    closeButton: {
      top: 6,
      insetEnd: 10,
    },
    dialogContainer: {
      alignItems: "center",
      height: "95dvh",
    },
    dialog: {
      my: "auto",
      mx: "auto",
    },
  },
  defaultProps: {
    size: "3xl",
  },
});
