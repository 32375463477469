import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  Text,
} from "@chakra-ui/react";

export interface CheckboxProps extends ChakraCheckboxProps {
  label: string;
  subLabel?: string;
  variant?: "card" | "full-width";
}

export function Checkbox({
  value,
  name,
  label,
  subLabel,
  variant,
  ...rest
}: CheckboxProps) {
  return (
    <ChakraCheckbox
      name={name}
      value={value}
      variant={variant}
      isChecked={typeof value === "boolean" ? value : undefined}
      {...rest}
    >
      {label}
      {typeof subLabel === "string" ? (
        <Text fontSize={"sm"} fontWeight={"400"} mt={2}>
          {subLabel}
        </Text>
      ) : (
        subLabel
      )}
    </ChakraCheckbox>
  );
}
