import { Box } from "@chakra-ui/react";
import { PrivacyDetails } from "features/privacy-app/screens/privacy/components/privacy-details";
import { ConsentTypes } from "features/privacy-app/screens/privacy/components/consent-types";
import { usePrivacySettings } from "features/privacy-app/api/privacy-settings";
import { Panel } from "features/privacy-app/components/panel";
import { PageHeader } from "features/privacy-app/patterns/layout";

export function Privacy() {
  const { getPrivacySettings } = usePrivacySettings();
  const { data: privacySettings, isLoading: privacyLoading } =
    getPrivacySettings();

  return (
    <Box>
      <PageHeader title={"Privacy"} />
      <ConsentTypes />
      <Panel isLoading={privacyLoading}>
        <PrivacyDetails privacySettings={privacySettings} />
      </Panel>
    </Box>
  );
}
