const componentFiles: Record<string, object> = import.meta.glob("./**.ts", {
  eager: true,
});

let components = {};
for (const file of Object.values(componentFiles)) {
  components = { ...components, ...file };
}

export { components };
