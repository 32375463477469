import { useModal } from "@ebay/nice-modal-react";
import { Table } from "components/table";
import { Panel } from "features/privacy-app/components/panel";
import { User, useUsers } from "features/privacy-app/api/users";
import { FullNameCell } from "features/privacy-app/components/table/full-name-cell";
import { InviteUser } from "features/privacy-app/screens/users/modals/invite-user";
import { Text } from "@chakra-ui/react";
import { RemoveUser } from "features/privacy-app/screens/users/modals/remove-user";

const COLUMNS = [
  {
    label: "Name",
    id: "first_name",
    Cell: FullNameCell,
  },
  {
    label: "Email",
    id: "email",
  },
];

export function UsersTable() {
  const { getUsers } = useUsers();
  const { data: users, isLoading } = getUsers();
  const removeUserModal = useModal(RemoveUser);

  const modal = useModal(InviteUser);

  return (
    <Panel>
      <Table<User>
        isLoading={isLoading}
        columns={COLUMNS}
        rows={users?.data || []}
        actions={[
          {
            label: <Text color={"red.600"}>Remove</Text>,
            id: "remove",
            onClick: ({ row: user }: { row: User }) => {
              removeUserModal.show({ user });
            },
          },
        ]}
        emptyProps={{
          emptyMessage: "No users",
          emptyDesc: "To add a user, click below.",
          addLabel: "Invite user",
          onAdd: modal.show,
        }}
      />
    </Panel>
  );
}
