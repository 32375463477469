import * as ReactDOM from "react-dom/client";
import "index.css";
import { Outlet } from "@tanstack/react-location";
import { AppProviders } from "components/app-providers";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <AppProviders>
    <Outlet />
  </AppProviders>
);
