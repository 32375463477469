import { Button, Card, Flex, Text } from "@chakra-ui/react";
import { Success } from "components/icons/success";

export interface InformationProps {
  message: string;
  description: string;
  actionText?: string;
  onAction?: () => void;
}

export function InformationCard({
  message,
  description,
  actionText,
  onAction,
}: InformationProps) {
  return (
    <Card py={28} px={10}>
      <Flex direction={"column"} align={"center"} justify={"center"}>
        <Success />
        <Text fontSize={"lg"} fontWeight={"bold"} mt={7}>
          {message}
        </Text>
        <Text fontWeight={"medium"} mt={4} color={"gray.600"} align={"center"}>
          {description}
        </Text>
        {actionText && onAction && (
          <Button mt={6} onClick={onAction}>
            {actionText}
          </Button>
        )}
      </Flex>
    </Card>
  );
}
