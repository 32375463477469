import { CopyIcon } from "@chakra-ui/icons";
import { Button, Tooltip } from "@chakra-ui/react";
import { CellProps } from "components/table";
import { useClipboard } from "features/privacy-app/hooks/use-clipboard";

export function ConnectionToken({ value }: CellProps) {
  const { copyText } = useClipboard({
    successMessage: "Connection token copied!",
  });

  return (
    <Tooltip label={"Copy connection token"} hasArrow>
      <Button variant={"ghost"} onClick={() => copyText(value)}>
        <CopyIcon boxSize={18} />
      </Button>
    </Tooltip>
  );
}
