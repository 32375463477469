import { Text } from "@chakra-ui/react";
import { useModal } from "@ebay/nice-modal-react";
import { Table } from "components/table";
import { AppTypeCell } from "features/privacy-app/components/table/app-type-cell";
import { StatusCell } from "features/privacy-app/components/table/status-cell";
import { ViewConnectionToken } from "features/privacy-app/screens/data-sources/modals/view-connection-token";
import { Panel } from "features/privacy-app/components/panel";
import { RemoveDataSource } from "features/privacy-app/screens/data-sources/modals/remove-data-source";
import {
  DataSource,
  useDataSource,
} from "features/privacy-app/api/data-sources";
import { UpsertDataSource } from "features/privacy-app/screens/data-sources/modals/upsert-data-source";
import { ConnectionToken } from "features/privacy-app/components/table/connection-token";
import { useState } from "react";

const COLUMNS = [
  {
    label: "Data source",
    id: "name",
  },
  {
    label: "Status",
    id: "status",
    Cell: StatusCell,
  },
  {
    label: "Last activity date",
    id: "lastActivityDate",
  },
  {
    label: "App type",
    id: "app_type",
    Cell: AppTypeCell,
  },
  {
    label: "",
    id: "api_secret",
    Cell: ConnectionToken,
  },
];

export function DataSourcesTable() {
  const [page, setPage] = useState(1);
  const modal = useModal(ViewConnectionToken);
  const renameModal = useModal(UpsertDataSource);
  const removeModal = useModal(RemoveDataSource);

  const { getDataSources } = useDataSource();
  const { data: dataSources, isFetching, isLoading } = getDataSources(page);

  return (
    <Panel>
      <Table<DataSource>
        isLoading={isLoading}
        isFetching={isFetching}
        columns={COLUMNS}
        rows={dataSources?.data ?? []}
        meta={dataSources?.meta}
        setPage={setPage}
        actions={[
          {
            label: "View connection token",
            id: "view",
            onClick: ({ row: dataSource }: { row: DataSource }) => {
              modal.show({ connectionToken: dataSource.api_secret });
            },
          },
          {
            label: "Rename",
            id: "rename",
            onClick: ({ row: dataSource }: { row: DataSource }) =>
              renameModal.show({ dataSource }),
            addBottomDivider: true,
          },
          {
            label: <Text color={"red.600"}>Remove</Text>,
            id: "remove",
            onClick: ({ row: dataSource }: { row: DataSource }) => {
              removeModal.show({ dataSource });
            },
          },
        ]}
        emptyProps={{
          emptyMessage: "No data sources",
          emptyDesc:
            "Generate a connection token to start adding data sources to Single View",
        }}
      />
    </Panel>
  );
}
