import {
  ChakraTheme,
  extendTheme,
  theme as baseTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import { textStyles } from "features/privacy-app/theme/text-styles";
import { layerStyles } from "features/privacy-app/theme/layer-styles";
import "features/privacy-app/theme/default-props";
import { colors } from "features/privacy-app/theme/colors";
import { components } from "features/privacy-app/theme/components";
import { fontSizes } from "features/privacy-app/theme/font-sizes";
import { styles } from "features/privacy-app/theme/styles";
import { shadows } from "features/privacy-app/theme/shadows";
import { fonts } from "features/privacy-app/theme/fonts";

const privacyAppTheme = extendTheme(
  {
    ...baseTheme,
    textStyles,
    layerStyles,
    colors,
    components,
    fontSizes,
    shadows,
    styles,
    fonts,
  } as ChakraTheme,
  withDefaultColorScheme({ colorScheme: "brand" })
);

export { privacyAppTheme };
