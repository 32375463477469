import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { show as showModal } from "@ebay/nice-modal-react";
import { ErrorModal } from "components/error/error-modal";
import Bugsnag from "@bugsnag/js";

export const mutationCache = new MutationCache({
  onError: (error, variables, context, mutation) => {
    Bugsnag.notify(error as Error);
    if (mutation?.meta?.showErrorModal === false) return;
    showModal(ErrorModal, { error: error as Error });
  },
});

export const queryCache = new QueryCache({
  onError: (error, query) => {
    Bugsnag.notify(error as Error);
    if (query?.meta?.showErrorModal === false) return;
    showModal(ErrorModal, { error: error as Error });
  },
});

export const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: 0,
      retry: false,
    },
  },
});
