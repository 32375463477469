import { apiClient } from "utils/api-client/index";
import { ResponseInterceptor } from "utils/api-client/interceptors/index";
import { redirectToExternalUrlAndWait } from "utils/url";

let loginRequestSingleton: Promise<unknown> | null;

const EXCLUDE_PREFIXES = ["/consumer"];

const NOT_LOGGED_IN_CODE = 401;
const UNAUTHORISED_OR_WRONG_SILO_CODE = 403;

export const responseLoginIfUnauthorised: ResponseInterceptor = async ({
  response,
  path,
  config,
}) => {
  if (isRequestUnauthorised(response, path)) {
    // If unauthorised or silo ID/user is wrong, we take them to the login screen
    // to force them to select a silo
    if (
      response.status === UNAUTHORISED_OR_WRONG_SILO_CODE ||
      path === "/login"
    ) {
      await goToLoginScreen();
      return response;
    }

    if (!loginRequestSingleton) {
      loginRequestSingleton = (async () => {
        const loginRequest = fetch(
          import.meta.env.VITE_AUTHENTICATION_SERVICE_BACKEND_URL +
            "/login-jwt",
          {
            credentials: "include",
            method: "GET",
            mode: "cors",
          }
        );

        const authServiceLoginResponse = await loginRequest;

        if (authServiceLoginResponse.status === NOT_LOGGED_IN_CODE) {
          await goToLoginScreen();
          return response;
        }

        const { result: jwt } = await authServiceLoginResponse.json();

        await apiClient.post("/login", {
          token: jwt,
        });
      })();
    }

    await loginRequestSingleton;
    loginRequestSingleton = null;

    return (await apiClient.http(path, config)).response;
  } else {
    return response;
  }
};

function isRequestUnauthorised(response: Response, path: string) {
  return (
    [NOT_LOGGED_IN_CODE, UNAUTHORISED_OR_WRONG_SILO_CODE].includes(
      response.status
    ) && !EXCLUDE_PREFIXES.find((prefix) => path.startsWith(prefix))
  );
}

export async function goToLoginScreen() {
  return await redirectToExternalUrlAndWait(
    (window.location.href =
      import.meta.env.VITE_AUTHENTICATION_SERVICE_FRONTEND_URL +
      "?" +
      new URLSearchParams({
        app_id: "single_view",
        logout: "1",
      }).toString())
  );
}
