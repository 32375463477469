import { MutationForRecord, QueryForRecord } from "types/query-for-record";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "utils/api-client";
import { queryClient } from "lib/react-query";
import { Media } from "features/privacy-app/api/media";
import { FormValue } from "types/form";

export interface UpdatePrivacySettings {
  id?: string;
  data_officer_email: string | null;
  privacy_policy_link: string | null;
  business_name: string | null;
  branding_logo: Media | null;
}

export interface PrivacySettings {
  id?: string;
  data_officer_email: FormValue<string> | null;
  privacy_policy_link: FormValue<string> | null;
  business_name: FormValue<string> | null;
  branding_logo: FormValue<Media> | null;
}

const PRIVACY_KEY = "privacy";

interface UpdateParams {
  onSuccess?: (privacySettings: PrivacySettings) => void;
}

function getPrivacySettings(): QueryForRecord<PrivacySettings> {
  return {
    queryKey: [PRIVACY_KEY],
    queryFn: async () => {
      const res = await apiClient.get<PrivacySettings>(
        "/privacy/privacy-settings"
      );
      return res.data;
    },
  };
}

function updatePrivacySettings({
  onSuccess,
}: UpdateParams): MutationForRecord<
  PrivacySettings,
  Partial<UpdatePrivacySettings>
> {
  return {
    mutationFn: async (newSettings) => {
      const res = await apiClient.patch<PrivacySettings>(
        "/privacy/privacy-settings",
        newSettings
      );
      return res.data;
    },
    onSuccess: (newSettings) => {
      queryClient.setQueryData<PrivacySettings>(
        [PRIVACY_KEY],
        () => newSettings as PrivacySettings
      );
      onSuccess?.(newSettings);
    },
  };
}

export function usePrivacySettings() {
  return {
    getPrivacySettings: () => useQuery(getPrivacySettings()),
    updatePrivacySettings: (params: UpdateParams) =>
      useMutation(updatePrivacySettings(params)),
  };
}
