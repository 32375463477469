import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import { components } from "features/customer-dashboard/theme/components";
import { colors } from "features/customer-dashboard/theme/colors";
import { styles } from "features/customer-dashboard/theme/styles";

const customerDashboardTheme = extendTheme(
  {
    colors,
    components,
    styles,
  },
  withDefaultColorScheme({ colorScheme: "brand" })
);

export { customerDashboardTheme };
