import { RequestType } from "types/data-request";

export type RequestFormProps = {
  title: string;
  description: string;
  type: RequestType;
  path: string;
};

export const DATA_REQUESTS: Record<string, RequestFormProps> = {
  COPY: {
    title: "Request a copy of your data",
    description:
      "You have the right to access and receive a copy of your personal data, and other supplementary information.",
    path: "copy",
    type: "copy_of_data",
  },
  CORRECTION: {
    title: "Request data correction",
    description:
      "You have the right to have inaccurate personal data rectified, or completed if it is incomplete.",
    path: "correction",
    type: "data_correction",
  },
  REMOVAL: {
    title: "Request data removal",
    description:
      "You have the right to have personal data held up until the time of the request to be erased.",
    path: "removal",
    type: "data_removal",
  },
};
