import { Box, Button, Container, HStack, Text } from "@chakra-ui/react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { motion } from "framer-motion";

export function SaveCancelBar<Values extends FieldValues>({
  form,
  isLoading,
}: {
  form: UseFormReturn<Values>;
  isLoading?: boolean;
}) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeOut", duration: 0.15 }}
    >
      <Box
        pos={"fixed"}
        w={"100%"}
        bottom={0}
        left={0}
        right={0}
        bg={"white"}
        zIndex={1}
        display={"flex"}
        justifyContent={"flex-end"}
        shadow={"md"}
        py={[4, 4, 8]}
        px={4}
      >
        <Container maxW={"4xl"}>
          <HStack spacing={"2"} justifyContent={"flex-end"}>
            <Text color={"gray.400"} fontSize={"sm"} mr={4}>
              You have unsaved changes
            </Text>
            <Button colorScheme={"gray"} onClick={() => form.reset()}>
              Cancel
            </Button>
            <Button type={"submit"} isLoading={isLoading}>
              Submit
            </Button>
          </HStack>
        </Container>
      </Box>
    </motion.div>
  );
}
