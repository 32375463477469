import { LocationGenerics } from "lib/react-location";

export async function redirectToExternalUrlAndWait(url: string) {
  window.location.href = url;
  await new Promise((r) => setTimeout(r, 10000));
}

export function removeSearchParamFromUrl(
  param: keyof LocationGenerics["Search"]
) {
  const url = new URL(window.location.href);
  url.searchParams.delete(param);
  window.history.pushState({}, "", url);
}
