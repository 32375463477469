import { MutationForRecord } from "types/query-for-record";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "utils/api-client";
import { updateList } from "utils/react-query";
import { DataRequest } from "types/data-request";
import { useMatch } from "@tanstack/react-location";
import { LocationGenerics } from "lib/react-location";

interface UpsertParams {
  onSuccess?: () => void;
  token: string;
  remoteContactId: string;
}

const DATA_REQUEST_KEY = "data_request";

export function mutateDataRequest({
  onSuccess,
  remoteContactId,
  token,
}: UpsertParams): MutationForRecord<DataRequest> {
  return {
    mutationFn: async (dataRequest) => {
      const res = await apiClient.post<DataRequest>(
        "/consumer/data-requests",
        { ...dataRequest, remote_contact_id: remoteContactId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    },
    meta: {
      showErrorModal: false,
    },
    onSuccess: (dataRequest) => {
      updateList([DATA_REQUEST_KEY], dataRequest);
      onSuccess?.();
    },
  };
}

export function useDataRequest() {
  const { search, params } = useMatch<LocationGenerics>();
  const token = search.token as string;
  const remoteContactId = params.id;
  return {
    storeDataRequest: (
      params: Omit<UpsertParams, "token" | "remoteContactId">
    ) => useMutation(mutateDataRequest({ ...params, token, remoteContactId })),
  };
}
