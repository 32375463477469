import {
  Alert,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import {
  DataSource,
  useDataSource,
} from "features/privacy-app/api/data-sources";
import { useCallback } from "react";

export const RemoveDataSource = createModal(
  ({ dataSource }: { dataSource: DataSource }) => {
    const modal = useModal();
    const toast = useToast();

    const { deleteDataSource } = useDataSource();
    const { mutate: mutateDataSource, isLoading } = deleteDataSource({
      onSuccess: () => {
        toast({
          title: "Data source removed",
          status: "success",
        });
        modal.hide();
      },
    });

    const onRemove = useCallback(
      () => mutateDataSource(dataSource),
      [dataSource]
    );

    return (
      <Modal
        isOpen={modal.visible}
        onClose={modal.hide}
        onCloseComplete={modal.remove}
      >
        <ModalOverlay />
        <ModalContent w={600}>
          <ModalHeader>Remove connection</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={"md"} fontWeight={"medium"} mb={5}>
              Are you sure you want to remove this connection?
            </Text>

            <Alert status={"warning"} alignItems={"flex-start"}>
              <AlertIcon />
              Your contact’s consent data will be permanently removed after 30
              days. Reconnecting the account within 30 days of removal will not
              restore any data, but you can contact Customer Support for
              assistance.
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button variant={"ghost"} onClick={modal.hide}>
              Cancel
            </Button>
            <Button isLoading={isLoading} onClick={onRemove}>
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);
