import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { tableAnatomy } from "@chakra-ui/anatomy";

export const Table = createMultiStyleConfigHelpers(
  tableAnatomy.keys
).defineMultiStyleConfig({
  baseStyle: {
    thead: {
      bgColor: "gray.50",
    },
    th: {
      textTransform: "unset",
      color: "gray.600",
    },
    td: {
      color: "gray.700",
      whiteSpace: "normal",
    },
  },
  variants: {
    simple: {
      th: {
        borderColor: "gray.200",
        fontSize: "sm",
        fontWeight: "medium",
        py: 3.5,
      },
      td: {
        borderColor: "gray.200",
      },
    },
  },
});
