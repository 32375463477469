import { QueryForRecord } from "types/query-for-record";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "utils/api-client";
import { getCookie } from "utils/cookies";
import {
  getLocalStorage,
  setLocalStorage,
  STORAGE_KEYS,
} from "features/privacy-app/utils/local-storage";

const QUERY_KEY = "user";

interface Silo {
  database: string;
  display_name: string;
  id: string;
  internal_name: string;
  region: string;
}

interface LoginMetadataCookie {
  first_name: string;
  last_name: string;
  email: string;
  last_login_at: string;
  last_login_app_id?: string | null;
  last_login_account_id?: string | null;
  login_remember_duration_minutes: number;
}

function getAccessibleSilos(): QueryForRecord<Silo[]> {
  return {
    queryKey: [QUERY_KEY, "accessible-silos"],
    queryFn: async () => {
      return (await apiClient.get<Silo[]>("/accessible-silos")).data;
    },
  };
}

export function useAuthentication() {
  return {
    getAccessibleSilos: () => useQuery(getAccessibleSilos()),
    getUser: () => ({
      data: getCookie<LoginMetadataCookie>(
        "authentication_service_login_metadata"
      ),
    }),
    getCurrentSilo: () => getLocalStorage(STORAGE_KEYS.SILO_ID),
    switchToSilo: (siloId: string) => {
      setLocalStorage(STORAGE_KEYS.SILO_ID, siloId);
      window.location.reload();
    },
  };
}
