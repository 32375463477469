import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import {
  CopyConnectionToken,
  ViewTokenProps,
} from "features/privacy-app/screens/data-sources/modals/view-connection-token";

export const ConnectionToken = createModal<ViewTokenProps>(
  ({ connectionToken }) => {
    const modal = useModal();

    const onClose = () => modal.hide().then(modal.remove);

    return (
      <Modal
        isOpen={modal.visible}
        onClose={modal.hide}
        onCloseComplete={modal.remove}
      >
        <ModalOverlay />
        <ModalContent w={600}>
          <ModalHeader>Connection token</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction={"column"} gap={5}>
              <CopyConnectionToken connectionToken={connectionToken} />
              <Alert
                status={"warning"}
                alignItems={"flex-start"}
                shadow={"none"}
              >
                <AlertIcon />
                <AlertDescription>
                  After you have copied your token, log in to your data source
                  and go to Settings &gt; Single View &gt; Connections and click
                  add to complete the connection.
                </AlertDescription>
              </Alert>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant={"ghost"} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onClose}>Done</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);
