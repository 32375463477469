import { CellProps } from "components/table";
import { useMemo } from "react";

export function DateCell({ value }: CellProps) {
  return useMemo(() => {
    const dateValue = new Date(value);
    return (
      <>
        {dateValue.getDay()}{" "}
        {dateValue.toLocaleString("en-US", {
          month: "long",
        })}{" "}
        {dateValue.getFullYear()} at {dateValue.getHours()}:
        {dateValue.getMinutes()}
      </>
    );
  }, [value]);
}
