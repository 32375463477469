import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { menuAnatomy } from "@chakra-ui/anatomy";
import { cssVar } from "@chakra-ui/theme-tools";

const $bg = cssVar("menu-bg");

export const Menu = createMultiStyleConfigHelpers(
  menuAnatomy.keys
).defineMultiStyleConfig({
  baseStyle: {
    button: {},
    list: {
      boxShadow: "lg",
    },
    item: {
      transitionDuration: false,
      _focus: {
        [$bg.variable]: "colors.gray.100",
      },
      _active: {
        [$bg.variable]: "colors.gray.200",
      },
    },
  },
});
