import { Text } from "@chakra-ui/react";
import { ReactNode } from "react";

export function MultiValueCell({ value }: { value: ReactNode[] }) {
  return (
    <>
      {value.map((v, index) => (
        <Text key={index} fontSize={"md"}>
          {v}
        </Text>
      ))}
    </>
  );
}
