import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { ReactNode } from "react";
import { Link } from "@tanstack/react-location";

export function PageCard({
  children,
  heading,
  summary,
  returnUrl,
}: {
  children: ReactNode;
  heading?: ReactNode;
  summary?: ReactNode;
  returnUrl?: string;
}) {
  return (
    <Container maxW={"4xl"} px={0} mt={[-5, -7]}>
      <Card>
        <CardHeader pb={0}>
          <Button
            display={"inline-flex"}
            alignItems={"center"}
            variant={"unstyled"}
            leftIcon={<ChevronLeftIcon />}
            as={Link}
            to={returnUrl || "../preferences"}
            search
          >
            Back
          </Button>
        </CardHeader>
        <CardBody>
          <VStack spacing={2} align={"flex-start"} mb={[6, 8]}>
            {typeof heading === "string" ? (
              <Heading as={"h1"} size={"md"}>
                {heading}
              </Heading>
            ) : (
              heading
            )}
            {typeof summary === "string" ? (
              <Text color={"gray.600"}>{summary}</Text>
            ) : (
              summary
            )}
          </VStack>
          {children}
        </CardBody>
      </Card>
    </Container>
  );
}
