import { MakeGenerics, ReactLocation } from "@tanstack/react-location";
import { extendTheme } from "@chakra-ui/react";

export type LocationGenerics = MakeGenerics<{
  RouteMeta: {
    title?: string;
    theme?: ReturnType<typeof extendTheme>;
    showTabs?: boolean;
  };
  Search: {
    token?: string;
    signature?: string;
    account_id?: string;
    origin?: string;
  };
}>;
export const location = new ReactLocation<LocationGenerics>();
