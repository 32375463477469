export enum STORAGE_KEYS {
  SILO_ID = "silo-id",
}

interface STORAGE_VALUES {
  [STORAGE_KEYS.SILO_ID]: string;
}

export function setLocalStorage<T extends STORAGE_KEYS>(
  key: T,
  value: STORAGE_VALUES[T]
): void {
  localStorage.setItem(
    key,
    typeof value === "string" ? value : JSON.stringify(value)
  );
}

export function getLocalStorage<T extends STORAGE_KEYS>(
  key: T
): STORAGE_VALUES[T] | null {
  let item = localStorage.getItem(key);

  if (!item) return null;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return item;
  }

  return item;
}
