import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "utils/api-client";
import { MutationForRecord, QueryForRecord } from "types/query-for-record";
import { useMatch } from "@tanstack/react-location";
import { LocationGenerics } from "lib/react-location";
import { AppError } from "errors";
import { QueryParams } from "features/customer-dashboard/types/query-params";
import { Pagination } from "types/pagination";

export interface Activity {
  author_name: string;
  data_source_id: string;
  details: string;
  id: string;
  ip_address: string;
  profile_id: string;
  properties: string;
  subject: string;
  title: string;
  url: string;
  created_at: string;
}

export const CUSTOMER_KEY = "customer";

interface UpdateParams {
  onSuccess?: () => void;
  token: string;
}

export interface ResendLinkParams {
  token: string;
  remoteContactId: string;
  onSuccess: () => void;
}

export function getActivityHistory({
  remoteContactId,
  token,
  page,
}: QueryParams & { page: number }): QueryForRecord<Pagination<Activity>> {
  return {
    queryKey: [CUSTOMER_KEY, "activity-history", page],
    queryFn: async () => {
      return (await apiClient.get(
        `/consumer/contact-profiles/${remoteContactId}/activity-history?page=${page}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )) as Pagination<Activity>;
    },
    keepPreviousData: true,
  };
}

export function sendEnquiry({
  token,
}: UpdateParams): MutationForRecord<{ message: string }> {
  return {
    mutationFn: async ({ message }) => {
      await apiClient.post(
        `/consumer/contact-profiles/enquiries`,
        { message },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return { message };
    },
  };
}

export function resendInviteLink({
  remoteContactId,
  token,
  onSuccess,
}: ResendLinkParams) {
  return {
    mutationFn: async () => {
      return apiClient.post(
        `/consumer/contact-profiles/${remoteContactId}/resend-link`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onSuccess,
  };
}

export function useCustomer() {
  const { params, search } = useMatch<LocationGenerics>();

  if (!search.token) {
    // TODO better error handling/404
    throw new AppError("No token provided in the URL");
  }

  const token = search.token as string;
  const remoteContactId = params.id;

  return {
    getActivityHistory: (page: number) =>
      useQuery(
        getActivityHistory({
          remoteContactId,
          token,
          page,
        })
      ),
    sendEnquiry: () => useMutation(sendEnquiry({ token })),
    resendInviteLink: ({
      onSuccess,
    }: {
      onSuccess: ResendLinkParams["onSuccess"];
    }) => useMutation(resendInviteLink({ onSuccess, remoteContactId, token })),
  };
}
