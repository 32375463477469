export function getCookie<CookieType>(name: string) {
  if (!document.cookie) {
    return null;
  }

  return (getAllCookies()[name] as CookieType) || null;
}

export function getAllCookies(): Record<
  string,
  string | Record<string, unknown>
> {
  return document.cookie
    .split(";")
    .map((c) => c.trim())
    .reduce(
      (accum: Record<string, string | Record<string, unknown>>, rawCookie) => {
        const [name, value] = rawCookie.split("=");
        accum[name] = maybeParseJson(decodeURIComponent(value));
        return accum;
      },
      {}
    );
}

function maybeParseJson(str: string): Record<string, unknown> | string {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}
