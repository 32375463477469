import {
  DEFAULT_API_ORIGIN,
  getBaseApiOrigin,
  RequestConfig,
  RequestPath,
} from "utils/api-client/index";

export async function cloneRequest(
  request: Request,
  path: RequestPath,
  config: RequestConfig
): Promise<Request> {
  // HACK: Binary bodies (file uploads)
  // can't be properly cloned unless we parse
  // them like so
  const body =
    request.body instanceof ReadableStream
      ? (await request.body?.getReader?.().read?.())?.value
      : request.body;

  return buildRequest(path, {
    ...config,
    method: request.method,
    headers: createHeaders(request.headers),
    body,
  });
}

export function buildRequestUrlFromPath(path: RequestPath) {
  return `${getBaseApiOrigin() || DEFAULT_API_ORIGIN}/api${path}`;
}

export function buildRequest(
  path: RequestPath,
  config: RequestConfig
): Request {
  const headers = createHeaders(config.headers);
  return new Request(buildRequestUrlFromPath(path), {
    ...config,
    headers,
  });
}

function createHeaders(
  input: Partial<Record<string, unknown>> | undefined | Headers
): Headers {
  if (input instanceof Headers) return input;
  if (!input) return new Headers();
  const definedEntries: Record<string, string> = {};

  for (const key in input) {
    if (input[key]) {
      definedEntries[key] = <string>input[key];
    }
  }

  return new Headers(definedEntries);
}
