import { Text } from "@chakra-ui/react";

export function NameEmailCell({
  row,
}: {
  row: { first_name: string; last_name: string; email: string };
}) {
  return (
    <>
      <Text fontSize={"md"} fontWeight={"medium"}>
        {row.first_name} {row.last_name}
      </Text>
      <Text fontSize={"md"}>{row.email}</Text>
    </>
  );
}
