import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
} from "@chakra-ui/react";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { Profile, ProfileContact } from "features/privacy-app/api/profiles";
import { LabelValue } from "features/privacy-app/components/label-value";
import { Table, TableColumn } from "components/table/index";
import { MultiValueCell } from "features/privacy-app/components/table/multi-value-cell";
import { DataSource } from "features/privacy-app/api/data-sources";
import { LinkCell } from "features/privacy-app/components/table/link-cell";

interface ProfileModalProps {
  profile?: Profile;
}

const COLUMNS: TableColumn<ProfileContact>[] = [
  {
    id: "remote_id",
    label: "Remote ID",
    Cell: LinkCell,
    formatValue: (value: string, row: ProfileContact) =>
      `${import.meta.env.VITE_APP_REX_URL}/contacts/#id=${value}&_account_id=${
        row.data_source.remote_id
      }`,
  },
  {
    id: "names",
    label: "Names",
    Cell: MultiValueCell,
    formatValue: (names: ProfileContact["names"]) =>
      names && names.length
        ? names.map((n) => `${n.first_name} ${n.last_name}`)
        : "",
  },
  {
    id: "emails",
    label: "Emails",
    Cell: MultiValueCell,
    formatValue: (emails: ProfileContact["emails"]) =>
      emails?.map((e) => e.email),
  },
  {
    id: "data_source",
    label: "Data source",
    formatValue: (dataSource: DataSource) => dataSource.name,
  },
];

export const ProfileModal = createModal<ProfileModalProps>(({ profile }) => {
  const modal = useModal();

  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.hide}
      onCloseComplete={modal.remove}
      closeOnOverlayClick={false}
      size={"4xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Profile</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {profile?.is_unsubscribed_all_enabled ? (
            <Alert status={"error"} mb={6}>
              <AlertIcon />
              <AlertTitle>Unsubscribed</AlertTitle>
              <AlertDescription>
                This profile has unsubscribed from all communications
              </AlertDescription>
            </Alert>
          ) : null}
          <LabelValue label={"ID"} value={profile?.id} mb={6} />
          <SimpleGrid columns={4} spacing={6} mb={6}>
            <LabelValue
              label={"Emails"}
              value={profile?.is_email_enabled ? "Yes" : "No"}
            />
            <LabelValue
              label={"Phone calls"}
              value={profile?.is_phone_call_enabled ? "Yes" : "No"}
            />
            <LabelValue
              label={"Letters"}
              value={profile?.is_letter_enabled ? "Yes" : "No"}
            />
            <LabelValue
              label={"SMS"}
              value={profile?.is_sms_enabled ? "Yes" : "No"}
            />
          </SimpleGrid>

          <Table<ProfileContact>
            columns={COLUMNS}
            rows={profile?.contacts || []}
            emptyProps={{
              emptyMessage: "No contacts",
              emptyDesc:
                "No contacts associated with this profile could be found",
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
