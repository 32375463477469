import { RouteArray } from "routes";
import { privacyAppTheme } from "features/privacy-app/theme";
import { DataRequests } from "features/privacy-app/screens/data-requests";
import { Privacy } from "features/privacy-app/screens/privacy/";
import { Users } from "features/privacy-app/screens/users";
import { DataSources } from "features/privacy-app/screens/data-sources/";
import { Layout } from "features/privacy-app/patterns/layout";
import { Navigate } from "@tanstack/react-location";
import { apiClient, setBaseApiOrigin } from "utils/api-client";
import {
  redirectToExternalUrlAndWait,
  removeSearchParamFromUrl,
} from "utils/url";
import {
  setLocalStorage,
  STORAGE_KEYS,
} from "features/privacy-app/utils/local-storage";
import { getCookie } from "utils/cookies";
import { goToLoginScreen } from "utils/api-client/interceptors/response/response-login-if-unauthorised";
import { Profiles } from "features/privacy-app/screens/profiles/index";

class LoginMetadataCookie {}

export const routes: RouteArray = [
  {
    path: "privacy-app",
    meta: {
      theme: privacyAppTheme,
    },
    onMatch: (match) => {
      const { account_id, origin } = match.search;

      if (
        !getCookie<LoginMetadataCookie>("authentication_service_login_metadata")
      ) {
        goToLoginScreen();
      }

      if (account_id) {
        setLocalStorage(STORAGE_KEYS.SILO_ID, account_id);
        removeSearchParamFromUrl("account_id");
      }

      if (origin) {
        setBaseApiOrigin(origin);
        removeSearchParamFromUrl("origin");
      }
    },
    element: <Layout />,
    children: [
      {
        path: "/accept-invite",
        loader: async ({ search }) => {
          try {
            await apiClient.post("/users/accept-invite", {
              token: search.signature,
            });
          } catch (error) {
            await redirectToExternalUrlAndWait(
              import.meta.env.VITE_AUTHENTICATION_SERVICE_FRONTEND_URL +
                "?" +
                new URLSearchParams({
                  app_id: "single_view",
                  logout: "1",
                  error: "ERR_INVITE_INVALID",
                }).toString()
            );
            return {};
          }

          window.location.href =
            import.meta.env.VITE_AUTHENTICATION_SERVICE_FRONTEND_URL +
            "?" +
            new URLSearchParams({
              app_id: "single_view",
              logout: "1",
            }).toString();

          return {};
        },
        element: <Navigate to={"/privacy-app/data-requests"} replace={true} />,
      },
      {
        path: "/data-requests",
        meta: {
          title: "Data requests",
        },
        element: <DataRequests />,
      },
      {
        path: "/privacy",
        meta: {
          title: "Privacy",
        },
        element: <Privacy />,
      },
      {
        path: "/users",
        meta: {
          title: "Users",
        },
        element: <Users />,
      },
      {
        path: "/data-sources",
        meta: {
          title: "Data sources",
        },
        element: <DataSources />,
      },
      {
        path: "/profiles",
        meta: {
          title: "Profiles",
        },
        element: <Profiles />,
      },
      {
        element: <Navigate to={"/privacy-app/data-requests"} replace={true} />,
        meta: {
          title: "Privacy App",
        },
      },
    ],
  },
];
