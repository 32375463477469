import { chakra, ChakraProps } from "@chakra-ui/react";

export function UsersIcon(props: ChakraProps) {
  return (
    <chakra.svg
      width={"18"}
      height={"18"}
      viewBox={"0 0 18 18"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...props}
    >
      <chakra.path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M10.5022 3.46009C9.48677 2.50899 7.96199 2.51332 6.95123 3.47309C6.0204 4.35694 5.99476 5.96468 7.00453 7.04711C7.87408 7.97922 9.44857 8.00685 10.516 6.99333C11.4753 6.08237 11.5185 4.49947 10.5022 3.46009ZM5.22979 1.66017C7.20993 -0.220058 10.2572 -0.220058 12.2374 1.66017C12.2465 1.66887 12.2556 1.6777 12.2645 1.68667C14.23 3.66966 14.2694 6.87681 12.2374 8.80624C10.3093 10.6371 7.12622 10.8425 5.17648 8.75246C3.36697 6.81276 3.16512 3.62067 5.22979 1.66017ZM6.2482 14C4.03751 14 2.75 15.3343 2.75 16.5C2.75 17.1904 2.19036 17.75 1.5 17.75C0.809644 17.75 0.25 17.1904 0.25 16.5C0.25 13.4044 3.27904 11.5 6.2482 11.5H11.7518C14.8689 11.5 17.75 13.5212 17.75 16.5C17.75 17.1904 17.1904 17.75 16.5 17.75C15.8096 17.75 15.25 17.1904 15.25 16.5C15.25 15.3878 14.0303 14 11.7518 14H6.2482Z"
        }
        fill={"white"}
      />
    </chakra.svg>
  );
}
