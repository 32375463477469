import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { tabsAnatomy } from "@chakra-ui/anatomy";
import { cssVar } from "@chakra-ui/theme-tools";

const $color = cssVar("tabs-color");

export const Tabs = createMultiStyleConfigHelpers(
  tabsAnatomy.keys
).defineMultiStyleConfig({
  defaultProps: {
    size: "lg",
  },
  baseStyle: {
    tab: {
      [$color.variable]: "colors.gray.600",
    },
    tabpanel: {
      px: 0,
      pb: 0,
    },
  },
  variants: {
    line: {
      tablist: {
        borderBottom: "1px solid",
      },
    },
  },
  sizes: {
    lg: {
      tab: {
        fontSize: "md",
      },
    },
  },
});
