import { defineStyleConfig } from "@chakra-ui/styled-system";
import { layerStyles } from "features/privacy-app/theme/layer-styles";

export const Textarea = defineStyleConfig({
  variants: {
    outline: {
      ...layerStyles["input-container"],
    },
  },
  sizes: {
    md: {
      px: "2.5",
    },
  },
});
