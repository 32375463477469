import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "utils/api-client";
import { MutationForRecord, QueryForRecord } from "types/query-for-record";
import { queryClient } from "lib/react-query";
import { pick } from "utils/collection";
import { useMatch } from "@tanstack/react-location";
import { LocationGenerics } from "lib/react-location";
import { AppError } from "errors";
import { QueryParams } from "features/customer-dashboard/types/query-params";
import { getModifiedFields } from "utils/form";
import { CUSTOMER_KEY } from "features/customer-dashboard/api/customer";
import { FormValue } from "types/form";

export interface Media {
  id: string | null;
  path: string | null;
  file_name: string | null;
  extension: string | null;
}

export interface Consent {
  id: string;
  name: string;
  is_enabled: boolean;
}

interface ConsentType extends Consent {
  description: string;
  updated_at: string;
}

export interface ConsentGroup {
  group: string;
  items: Consent[];
}

export interface Guest {
  email: string;
  family_name: string;
  given_name: string;
  type: "consumer" | "user";
}

export interface ConsentPreferences {
  profile_id: string;
  is_phone_call_enabled: boolean;
  is_sms_enabled: boolean;
  is_email_enabled: boolean;
  is_letter_enabled: boolean;
  is_unsubscribed_all_enabled: boolean;
  consent_types: ConsentType[];
  lists: ConsentGroup[];
  campaigns: ConsentGroup[];
  guest: Guest;
  silo_settings: {
    branding_logo: FormValue<Media> | null;
    business_name: FormValue<string> | null;
    privacy_policy_link: FormValue<string> | null;
  };
}

interface UpdateParams {
  onSuccess?: (newConsentPreferences: ConsentPreferences) => void;
  token: string;
  remoteContactId: string;
}

const CONSENT_PREFERENCES_KEY = "consent_preferences";

export function getConsentPreferences({
  remoteContactId,
  token,
}: QueryParams): QueryForRecord<ConsentPreferences> {
  return {
    queryKey: [CONSENT_PREFERENCES_KEY],
    queryFn: async () => {
      const res = await apiClient.get<ConsentPreferences>(
        `/consumer/contact-profiles/${remoteContactId}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    },
    suspense: true,
    meta: {
      showErrorModal: false,
    },
  };
}

export function updateConsentPreferences({
  onSuccess,
  remoteContactId,
  token,
}: UpdateParams): MutationForRecord<
  ConsentPreferences,
  Partial<ConsentPreferences>
> {
  return {
    mutationFn: async (consentPreferences) => {
      const res = await apiClient.patch<ConsentPreferences>(
        `/consumer/contact-profiles/${remoteContactId}`,
        consentPreferences,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    },
    onSuccess: (newConsentPreferences) => {
      queryClient.setQueryData<ConsentPreferences>(
        [CONSENT_PREFERENCES_KEY],
        () => {
          return newConsentPreferences;
        }
      );
      onSuccess?.(newConsentPreferences);
      queryClient.invalidateQueries({
        queryKey: [CUSTOMER_KEY, "activity-history"],
      });
    },
  };
}

export function useConsentPreferences() {
  const { params, search } = useMatch<LocationGenerics>();

  if (!search.token) {
    // TODO better error handling/404
    throw new AppError("No token provided in the URL");
  }

  const token = search.token as string;
  const remoteContactId = params.id;

  return {
    getConsentPreferences: () =>
      useQuery(
        getConsentPreferences({
          remoteContactId,
          token: search.token as string,
        })
      ),
    updateConsentPreferences: (
      params: Omit<UpdateParams, "token" | "remoteContactId">
    ) =>
      useMutation(
        updateConsentPreferences({ ...params, token, remoteContactId })
      ),
  };
}

export interface CheckboxField {
  [id: string]: Consent;
}

export interface FormData
  extends Omit<ConsentPreferences, "consent_types" | "lists" | "campaigns"> {
  consent_types: CheckboxField;
  lists: CheckboxField;
  campaigns: CheckboxField;
}

export function consentPreferencesToFormData(
  consentPreferences?: ConsentPreferences
) {
  if (!consentPreferences) return undefined;

  const formData: FormData = {
    ...consentPreferences,
    consent_types: {},
    lists: {},
    campaigns: {},
  };

  consentPreferences.consent_types.forEach((consentType) =>
    Object.assign(formData.consent_types, {
      [consentType.id]: consentType,
    })
  );
  consentPreferences.lists.forEach((group) =>
    group.items.forEach((item) =>
      Object.assign(formData.lists, {
        [item.id]: item,
      })
    )
  );
  consentPreferences.campaigns.forEach((group) =>
    group.items.forEach((item) =>
      Object.assign(formData.campaigns, {
        [item.id]: item,
      })
    )
  );

  return formData;
}

function checkboxFieldToConsentList(
  consent: "consent_types" | "lists" | "campaigns",
  formData: FormData,
  dirtyFields: Partial<Record<keyof FormData, boolean>>
) {
  return Object.keys((dirtyFields as CheckboxField)[consent]).map((id) => {
    // Read value from form data because dirtyFields value sometimes isn't updated
    const field = formData[consent] as CheckboxField;
    return pick(field[id], "id", "name", "is_enabled");
  });
}

export function formDataToConsentPreferences(
  formData: FormData,
  dirtyFields: Partial<Record<keyof FormData, boolean>>
) {
  // Read value from form data because dirtyFields value sometimes isn't updated
  const values: Partial<
    Omit<ConsentPreferences, "profile_id" | "guest" | "branding_logo">
  > = {
    ...pick(
      getModifiedFields(formData, dirtyFields),
      "is_phone_call_enabled",
      "is_sms_enabled",
      "is_email_enabled",
      "is_letter_enabled",
      "is_unsubscribed_all_enabled"
    ),
  };

  if (dirtyFields.consent_types) {
    Object.assign(values, {
      consent_types: checkboxFieldToConsentList(
        "consent_types",
        formData,
        dirtyFields
      ),
    });
  }

  if (dirtyFields.lists) {
    Object.assign(values, {
      lists: [
        {
          items: checkboxFieldToConsentList("lists", formData, dirtyFields),
        },
      ],
    });
  }

  if (dirtyFields.campaigns) {
    Object.assign(values, {
      campaigns: [
        {
          items: checkboxFieldToConsentList("campaigns", formData, dirtyFields),
        },
      ],
    });
  }

  return values;
}
