import { Box, Button, Spacer, Tab, TabList, Tabs } from "@chakra-ui/react";
import { PageContainer } from "features/customer-dashboard/components/page-container";
import { Link, Outlet, useLocation } from "@tanstack/react-location";
import { NavigationBar } from "features/customer-dashboard/patterns/navigation-bar";
import { usedMatchedRouteConfig } from "hooks/use-matched-route";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorScreen } from "features/customer-dashboard/screens/error-screen";
import { Footer } from "features/customer-dashboard/patterns/footer";

export function CustomerDashboard() {
  const currentLocation = useLocation();
  const currentRoute = usedMatchedRouteConfig();

  const tabs = [
    { label: "Preferences", to: "preferences" },
    { label: "Data requests", to: "data-requests" },
    { label: "History", to: "history" },
  ];

  const currentTab = tabs.findIndex((t) =>
    currentLocation.current.pathname.includes(t.to)
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <NavigationBar />
      <PageContainer>
        {currentRoute.meta?.showTabs && (
          <Tabs index={currentTab}>
            <TabList>
              {tabs.map((t) => (
                <Box
                  key={t.to}
                  display={["block", t.to === "history" ? "none" : "block"]}
                >
                  <Link to={t.to} search>
                    <Tab h={"full"}>{t.label}</Tab>
                  </Link>
                </Box>
              ))}
              <Spacer display={["none", "block"]} />
              <Box alignSelf={"center"} display={["none", "block"]}>
                <Link to={"history"} search>
                  <Button
                    variant={"ghost"}
                    size={"sm"}
                    colorScheme={"blue"}
                    whiteSpace={"normal"}
                  >
                    View your activity history
                  </Button>
                </Link>
              </Box>
            </TabList>
          </Tabs>
        )}
        <Box pt={[6, 8]} mb={16}>
          <Outlet />
        </Box>
        <Footer />
      </PageContainer>
    </ErrorBoundary>
  );
}
