import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { useCallback } from "react";
import { User, useUsers } from "features/privacy-app/api/users";
import { ErrorAlert } from "features/privacy-app/components/error-alert";

export const RemoveUser = createModal(({ user }: { user: User }) => {
  const modal = useModal();
  const toast = useToast();

  const { deleteUser } = useUsers();
  const {
    mutate: mutateUser,
    isLoading,
    error,
  } = deleteUser({
    onSuccess: () => {
      toast({
        title: "User removed from this account",
        status: "success",
      });
      modal.hide();
    },
  });

  const onRemove = useCallback(() => mutateUser(user), [user]);

  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.hide}
      onCloseComplete={modal.remove}
    >
      <ModalOverlay />
      <ModalContent w={600}>
        <ModalHeader>Remove user</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ErrorAlert error={error} />
          <Text fontSize={"md"} fontWeight={"medium"} mb={5}>
            Are you sure you want to remove {user.first_name} {user.last_name}{" "}
            from this account?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant={"ghost"} onClick={modal.hide}>
            Cancel
          </Button>
          <Button isLoading={isLoading} onClick={onRemove}>
            Remove
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
