import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { Form } from "components/form";
import { FormField } from "components/form-field";
import { useForm } from "react-hook-form";
import { ErrorAlert } from "features/privacy-app/components/error-alert";
import { User, useUsers } from "features/privacy-app/api/users";

export const InviteUser = createModal(() => {
  const form = useForm<User>();
  const modal = useModal();
  const toast = useToast();
  const { inviteUser } = useUsers();
  const {
    mutate: inviteUserMutate,
    error,
    isLoading,
  } = inviteUser({
    onSuccess: (user) => {
      modal.hide();
      toast({
        status: "success",
        title: `Invitation has been sent to ${user.email}`,
      });
    },
  });

  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.hide}
      onCloseComplete={modal.remove}
    >
      <ModalOverlay />
      <ModalContent w={600}>
        <Form form={form} onSubmit={(values) => inviteUserMutate(values)}>
          <ModalHeader>Invite new user</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={0}>
            <ErrorAlert error={error} />
            <FormField
              Input={Input}
              label={"Email"}
              name={"email"}
              inputProps={{
                placeholder: "Enter email",
              }}
              required
            />
          </ModalBody>
          <ModalFooter border={0}>
            <Button variant={"ghost"} onClick={modal.hide}>
              Cancel
            </Button>
            <Button isLoading={isLoading} type={"submit"}>
              Invite
            </Button>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
});
