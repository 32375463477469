import {
  BoxProps,
  Button,
  Flex,
  HStack,
  Image,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { Link } from "@tanstack/react-location";
import { useConsentPreferences } from "features/customer-dashboard/api/consent-preferences";

export function NavigationBar(props: BoxProps) {
  const { getConsentPreferences } = useConsentPreferences();

  const { data: consentPreferences } = getConsentPreferences();

  if (!consentPreferences) return null;

  return (
    <Flex
      w={"100%"}
      p={4}
      bg={"white"}
      align={"center"}
      pos={"sticky"}
      wrap={"wrap"}
      top={"0"}
      zIndex={1}
      shadow={"md"}
      gap={3}
      mb={[0, 6]}
      {...props}
    >
      <HStack
        spacing={6}
        justify={["space-between", "flex-start"]}
        w={["full", "max-content"]}
      >
        {consentPreferences.silo_settings.branding_logo?.value?.path ? (
          <Image
            src={consentPreferences.silo_settings.branding_logo?.value?.path}
            alt={consentPreferences.silo_settings.business_name?.value}
            maxH={"50px"}
          />
        ) : null}
        <Link to={"./contact"} search>
          <Button colorScheme={"gray"} variant={"outline"}>
            Contact Us
          </Button>
        </Link>
      </HStack>
      <Spacer display={["none", "block"]} />
      <Text>
        Logged in as{" "}
        <Text as={"span"} fontWeight={"semibold"}>
          {consentPreferences.guest.email}
        </Text>
      </Text>
    </Flex>
  );
}
