import { RequestInterceptor } from "utils/api-client/interceptors";
import {
  apiClient,
  DEFAULT_API_ORIGIN,
  getBaseApiOrigin,
  setBaseApiOrigin,
} from "utils/api-client/index";

export interface Region {
  region: string;
  origin: string;
}

let regionRequestSingleton: Promise<void> | null;

// Certain endpoints shouldn't kickoff an origin exchange
const EXCLUDED_ENDPOINTS = ["/api/login", "/api/csrf"];

export const requestEnsureOriginSet: RequestInterceptor = async ({
  request,
}) => {
  const origin = getBaseApiOrigin();
  if (
    origin ||
    EXCLUDED_ENDPOINTS.find((endpoint) => request.url.endsWith(endpoint))
  )
    return request;

  if (!regionRequestSingleton) {
    regionRequestSingleton = (async () => {
      const isConsumerApp = window.location.pathname.startsWith("/customer");

      setBaseApiOrigin(DEFAULT_API_ORIGIN);
      const regionRequest = apiClient.get<Region>(
        isConsumerApp ? "/consumer/regions/current" : "/regions/current"
      );
      setBaseApiOrigin(null);

      const { data } = await regionRequest;

      setBaseApiOrigin(data.origin);
    })();
  }

  await regionRequestSingleton;
  regionRequestSingleton = null;

  return request;
};
