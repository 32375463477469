import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { Panel } from "features/privacy-app/components/panel";
import { PageHeader } from "features/privacy-app/patterns/layout";
import { DataRequestsTable } from "features/privacy-app/screens/data-requests/components/data-requests-table";

export function DataRequests() {
  return (
    <Box>
      <PageHeader
        title={"Data requests"}
        description={
          "Customer requests for data copies, corrections, and removals. Requests older than 90 days will be automatically archived."
        }
      />
      <Panel>
        <Tabs isLazy>
          <TabList>
            <Tab>Active</Tab>
            <Tab>Archived</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <DataRequestsTable type={"active"} />
            </TabPanel>
            <TabPanel>
              <DataRequestsTable type={"archived"} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Panel>
    </Box>
  );
}
