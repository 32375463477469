import { SVGProps } from "react";

export function Logo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={"40"}
      height={"40"}
      viewBox={"0 0 40 40"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...props}
    >
      <path
        d={
          "M39.9534 19.6419C38.2018 -6.1754 1.26016 -6.8162 0.0788089 19.3456C-2.10095 46.7984 41.6743 46.8718 39.9504 19.6419H39.9534Z"
        }
        fill={"#319795"}
      />
      <path
        d={
          "M31.7121 26.6687C31.6191 26.7834 29.3493 29.4772 25.2316 29.4772C21.1387 29.4772 18.8193 26.7993 18.7203 26.6878C18.3853 26.2924 18.2179 25.7855 18.2519 25.2627C18.2862 24.7399 18.5188 24.2614 18.9032 23.9174C19.2628 23.5952 19.7093 23.4358 20.1558 23.4358C20.6828 23.4358 21.1572 23.7133 21.5882 24.0927C22.0536 24.5039 22.9216 25.5496 25.2316 25.5496C27.5384 25.5496 28.3293 24.5389 28.8068 24.118C29.1882 23.7802 29.5943 23.4805 30.1028 23.4389C30.6113 23.3977 31.1043 23.5602 31.4951 23.9014C31.8827 24.2424 32.1212 24.7174 32.1617 25.2402C32.2021 25.763 32.0437 26.2702 31.7121 26.6718V26.6687Z"
        }
        fill={"white"}
      />
      <path
        d={
          "M26.1773 17.308C27.0578 17.904 28.2423 17.6521 28.8222 16.7468C29.4018 15.8414 29.1568 14.6236 28.2763 14.0273L21.3897 9.37271C20.872 9.05707 20.2891 8.90088 19.7061 8.90088C19.1138 8.90088 18.5186 9.06345 17.9913 9.39183L11.229 13.5682C10.2366 14.1804 9.62891 15.2836 9.62891 16.4758V25.9158V27.3155C9.62891 28.3993 10.4846 29.2794 11.539 29.2794H11.9265C12.9809 29.2794 13.8365 28.3993 13.8365 27.3155V26.0624V17.8755C13.8365 17.1168 14.1653 16.5014 14.8568 16.023L18.7946 13.5713C19.3806 13.2079 20.1155 13.2207 20.6891 13.6067L26.1803 17.3143L26.1773 17.308Z"
        }
        fill={"white"}
      />
    </svg>
  );
}
