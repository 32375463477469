import { MutationForRecord, QueryForRecord } from "types/query-for-record";
import { Pagination } from "types/pagination";
import { apiClient } from "utils/api-client";
import { useMutation, useQuery } from "@tanstack/react-query";
import { filterFromList, updateList } from "utils/react-query";

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
}

const USER_KEY = "user";

function getUsers(): QueryForRecord<Pagination<User>> {
  return {
    queryKey: [USER_KEY],
    queryFn: async () => {
      return (await apiClient.get("/users")) as Pagination<User>;
    },
  };
}

interface UserParams {
  onSuccess?: (args: User) => void;
}

function inviteUser({ onSuccess }: UserParams): MutationForRecord<User> {
  return {
    mutationFn: async ({ email }) => {
      const { data: user } = await apiClient.post<User>(`/users`, { email });
      await apiClient.post(`/users/invite`, { id: user.id });
      return user;
    },
    meta: {
      showErrorModal: false,
    },
    onSuccess: (user) => {
      updateList([USER_KEY], user);
      onSuccess?.(user);
    },
  };
}

function deleteUser({ onSuccess }: UserParams): MutationForRecord<User> {
  return {
    mutationFn: async (user) => {
      await apiClient.delete<User>(`/users/${user.id}`);
      return user;
    },
    onSuccess: (user) => {
      filterFromList([USER_KEY], user);
      onSuccess?.(user);
    },
  };
}

export function useUsers() {
  return {
    getUsers: () => useQuery(getUsers()),
    inviteUser: (params: UserParams) => useMutation(inviteUser(params)),
    deleteUser: (params: UserParams) => useMutation(deleteUser(params)),
  };
}
