import * as React from "react";
import { Container, Heading, VStack } from "@chakra-ui/react";
import { ErrorContent } from "components/error/error-content";

export function ErrorScreen({ error }: { error: Error }) {
  return (
    <Container maxW={"2xl"} pt={"32"}>
      <VStack spacing={"4"} w={"100%"}>
        <Heading>An error occurred</Heading>
        <ErrorContent error={error} />
      </VStack>
    </Container>
  );
}
