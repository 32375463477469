import { AsyncElement, Route, UseGeneric } from "@tanstack/react-location";
import { Helmet } from "react-helmet-async";
import { location, LocationGenerics } from "lib/react-location";

/**
 *
 * ROUTING
 *
 * Rather than have to maintain a large index file , the router
 * instead searches the whole codebase for any "routes.tsx" files
 * and automatically imports them into the central router.
 *
 * Each routes.tsx file needs to have a single named export
 * - routes: RouteArray
 */

export type RouteArray = Route<LocationGenerics>[];

interface Modules {
  [modulePath: string]: { routes: Route<LocationGenerics>[] };
}

const modules: Modules = import.meta.glob("./features/**/routes.tsx", {
  // Setting "eager: true" ensures the glob operates synchronously
  eager: true,
});

/**
 * Wraps a route element in our auth guard if necessary,
 * based on parsing route.meta.isPublic
 */
function processRoute(route: Route<LocationGenerics>) {
  // Route elements can be either raw elements, or dynamic imports()/lazy loaded.
  // Checking for typeof === "function" is sufficient to be able
  // to differentiate between these two cases.
  const hasTitle = route.meta?.title;
  const isAsyncImport = typeof route.element === "function";

  let element = isAsyncImport
    ? async (opts: { params: UseGeneric<LocationGenerics, "Params"> }) => {
        return await (route.element as AsyncElement<LocationGenerics>)(opts);
      }
    : route.element;

  if (hasTitle) {
    element = (
      <>
        <Helmet>
          <title>Single View - {route.meta?.title}</title>
        </Helmet>
        {element}
      </>
    );
  }

  if (route.children?.length) {
    route.children = route.children.map(processRoute);
  }

  return {
    ...route,
    element,
  } as Route<LocationGenerics>;
}

const rootRoutes: Route<LocationGenerics>[] = [
  {
    path: "/",

    // BUG: can't use a <Navigate /> element on the top-level due to some sort of
    // issue with React Location - it only works when StrictMode is on
    onMatch: () =>
      location.navigate(
        {
          ...location.current,
          pathname: "/privacy-app/data-requests",
        },
        true
      ),
  },
];

// Once we've loaded our module routes, we need to smush them
// together into our global route object
const routes: Route<LocationGenerics>[] = Object.keys(modules)
  .map((module) => {
    const routes = modules[module].routes;

    return routes.map((route) => {
      return processRoute(route);
    });
  })
  .flat()
  .concat(rootRoutes);

export { routes };
