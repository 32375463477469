import { QueryForRecord } from "types/query-for-record";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "utils/api-client";
import { Pagination } from "types/pagination";
import { DataRequest } from "types/data-request";

interface ListParams {
  archived: 0 | 1;
}

const DATA_REQUEST_KEY = "data_request";

function getDataRequests({
  archived,
}: ListParams): QueryForRecord<Pagination<DataRequest>> {
  return {
    queryKey: [DATA_REQUEST_KEY, { archived }],
    queryFn: async () => {
      return (await apiClient.get("/data-requests", {
        archived,
      })) as Pagination<DataRequest>;
    },
  };
}

export function useDataRequest() {
  return {
    getDataRequests: (params: ListParams) => useQuery(getDataRequests(params)),
  };
}
