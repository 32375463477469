import { SVGProps } from "react";

export function Upload(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={"12"}
      height={"12"}
      viewBox={"0 0 12 12"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...props}
    >
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M5.99936 9.34676e-10C6.19829 -9.92324e-06 6.38907 0.0790084 6.52973 0.21967L9.53033 3.22027C9.82322 3.51316 9.82322 3.98804 9.53033 4.28093C9.23744 4.57382 8.76256 4.57382 8.46967 4.28093L6.75 2.56126V8.2506C6.75 8.66481 6.41421 9.0006 6 9.0006C5.58579 9.0006 5.25 8.66481 5.25 8.2506V2.56032L3.52978 4.28088C3.23692 4.5738 2.76204 4.57385 2.46912 4.28098C2.1762 3.98812 2.17615 3.51325 2.46902 3.22032L5.46902 0.219723C5.60966 0.0790474 5.80044 9.96981e-06 5.99936 9.34676e-10ZM0.75 11.25C0.75 10.8358 1.08579 10.5 1.5 10.5H10.5C10.9142 10.5 11.25 10.8358 11.25 11.25C11.25 11.6642 10.9142 12 10.5 12H1.5C1.08579 12 0.75 11.6642 0.75 11.25Z"
        }
        fill={"#2D3748"}
      />
    </svg>
  );
}
