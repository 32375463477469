import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { Form } from "components/form";
import { FormField } from "components/form-field";
import { CountedTextInput } from "components/input";
import { useForm } from "react-hook-form";
import {
  ConsentType,
  useConsentType,
} from "features/privacy-app/api/consent-type";
import { ErrorAlert } from "features/privacy-app/components/error-alert";

interface EditConsentTypeProps {
  consentType?: ConsentType;
}

export const EditConsentType = createModal<EditConsentTypeProps>(
  ({ consentType }) => {
    const modal = useModal();
    const form = useForm({
      defaultValues: consentType,
    });
    const { upsertConsentType } = useConsentType();
    const {
      mutate: mutateConsentType,
      isLoading,
      error,
    } = upsertConsentType({
      onSuccess: () => {
        modal.hide();
      },
    });

    const onSubmit = (values: ConsentType) => {
      mutateConsentType(values);
    };

    return (
      <Modal
        isOpen={modal.visible}
        onClose={modal.hide}
        onCloseComplete={modal.remove}
      >
        <ModalOverlay />
        <ModalContent w={600}>
          <ModalHeader>{consentType ? "Edit" : "Add"} consent type</ModalHeader>
          <ModalCloseButton />
          <Form form={form} onSubmit={onSubmit}>
            <ModalBody>
              <ErrorAlert error={error} />
              <VStack spacing={4}>
                <FormField
                  name={"name"}
                  label={"Name"}
                  Input={CountedTextInput}
                  required
                  inputProps={{
                    max: 50,
                    placeholder: "Enter consent name",
                    Input,
                  }}
                />
                <FormField
                  name={"description"}
                  label={"Description"}
                  Input={Textarea}
                  inputProps={{ placeholder: "Leave description here" }}
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button variant={"ghost"} onClick={() => modal.hide()}>
                Cancel
              </Button>
              <Button type={"submit"} isLoading={isLoading}>
                {consentType ? "Save changes" : "Add consent type"}
              </Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    );
  }
);
