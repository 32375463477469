export function get(
  data: Record<string, unknown>,
  keys: string,
  defaultValue?: string
): string | undefined {
  const value = keys
    .split(".")
    .reduce(
      (value, key) => (value as Record<string, unknown>)?.[key],
      data as unknown
    );

  return value !== undefined ? (value as string) : defaultValue;
}

export function groupBy<T>(arr: T[], key: keyof T): Record<keyof T, T[]> {
  return arr.reduce((accumulator, val) => {
    const groupedKey = val[key] as keyof T;
    if (!accumulator[groupedKey]) {
      accumulator[groupedKey] = [];
    }
    accumulator[groupedKey].push(val);
    return accumulator;
  }, {} as Record<keyof T, T[]>);
}

export function map<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  collection: any[] | Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: (item: any) => T
) {
  if (Array.isArray(collection)) {
    return collection.map(func);
  } else {
    return Object.keys(collection).map((key) => func(collection[key]));
  }
}

export function upsert<T extends { id: string }>(arr: T[], newData: T) {
  let isOld = false;
  const newArr = arr.map((prev) => {
    if (prev.id === newData.id) {
      isOld = true;
      return newData;
    }
    return prev;
  });

  return isOld ? newArr : [...arr, newData];
}

export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  const result = {};
  for (const key of keys) {
    if (obj[key] !== undefined) {
      Object.assign(result, { [key]: obj[key] });
    }
  }
  return result as Pick<T, K>;
}
