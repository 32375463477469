import Bugsnag from "@bugsnag/js";
import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  IconButton,
  Input,
  Tag,
  Text,
} from "@chakra-ui/react";
import { show as showModal, useModal } from "@ebay/nice-modal-react";
import { ErrorModal } from "components/error/error-modal";
import { Form } from "components/form";
import { FormField } from "components/form-field";
import { ImageUploadInput } from "components/input";
import { useMedia } from "features/privacy-app/api/media";
import {
  PrivacySettings,
  usePrivacySettings,
} from "features/privacy-app/api/privacy-settings";
import { EditIcon } from "features/privacy-app/components/icons";
import { useState } from "react";
import { useForm } from "react-hook-form";

interface FormValues extends Partial<Omit<PrivacySettings, "branding_logo">> {
  branding_logo?: {
    value: FileList;
  };
}

interface PrivacyDetailsProps {
  privacySettings?: PrivacySettings;
}

export function PrivacyDetails({ privacySettings }: PrivacyDetailsProps) {
  const errorModal = useModal(ErrorModal);

  const form = useForm<FormValues>({
    defaultValues: async () => {
      if (!privacySettings) return {};
      const { branding_logo } = privacySettings;
      if (branding_logo?.value?.path) {
        try {
          const media = await fetch(branding_logo.value.path);
          const mediaData = await media.blob();
          const logo = [
            new File(
              [mediaData],
              `${branding_logo.value.file_name}.${branding_logo.value.extension}`
            ),
          ] as unknown as FileList;
          return {
            ...privacySettings,
            branding_logo: {
              value: logo,
            },
          };
        } catch (error) {
          Bugsnag.notify(error as Error);
          showModal(ErrorModal, { error: error as Error });
          return {
            ...privacySettings,
            branding_logo: null,
          };
        }
      }
      return {
        ...privacySettings,
        branding_logo: null,
      };
    },
  });

  const [isEditing, setIsEditing] = useState(false);

  const { uploadMedia: uploadMediaQuery } = useMedia();
  const { mutateAsync: uploadMedia, isLoading: uploadLoading } =
    uploadMediaQuery();
  const { updatePrivacySettings: updatePrivacySettingsQuery } =
    usePrivacySettings();
  const { mutateAsync: updatePrivacySettings, isLoading: updateLoading } =
    updatePrivacySettingsQuery({
      onSuccess: () => {
        setIsEditing(false);
      },
    });

  const onSubmit = async (values: FormValues) => {
    try {
      let media = null;
      if (
        values.branding_logo?.value?.[0] &&
        form.formState.dirtyFields.branding_logo
      ) {
        media = await uploadMedia({ file: values.branding_logo.value[0] });
      }

      await updatePrivacySettings({
        id: values.id,
        branding_logo: media,
        business_name: values.business_name?.value ?? null,
        privacy_policy_link: values.privacy_policy_link?.value ?? null,
        data_officer_email: values.data_officer_email?.value ?? null,
      });

      form.reset(values);
    } catch (error) {
      errorModal.show({ error: error as Error });
    }
  };

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Flex justify={"space-between"} align={"center"} mb={3}>
        <Flex align={"center"}>
          <Text color={"gray.700"} fontWeight={"bold"}>
            Details
          </Text>
          {form.formState.isDirty && (
            <Tag ml={3} bgColor={"orange.100"} color={"orange.800"}>
              Unsaved changes
            </Tag>
          )}
        </Flex>
        {isEditing ? (
          <HStack spacing={2}>
            <Button
              variant={"ghost"}
              onClick={() => {
                form.reset();
                setIsEditing(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type={"submit"}
              isLoading={uploadLoading || updateLoading}
              isDisabled={uploadLoading || updateLoading}
            >
              Save
            </Button>
          </HStack>
        ) : (
          <IconButton
            aria-label={"Edit privacy details"}
            icon={<EditIcon />}
            variant={"ghost"}
            onClick={() => setIsEditing(true)}
          />
        )}
      </Flex>
      <Grid templateColumns={"1fr 1fr"} gap={5}>
        <FormField
          name={"business_name.value"}
          Input={Input}
          inputProps={{
            disabled: !isEditing,
          }}
          label={"Business name"}
          required
        />
        <FormField
          name={"data_officer_email.value"}
          Input={Input}
          inputProps={{
            disabled: !isEditing,
            placeholder: "e.g. dataprotectionofficer@yourwebsite.com",
          }}
          label={"Data protection officer email"}
          required
        />
        <FormField
          name={"branding_logo.value"}
          label={"Branding logo"}
          Input={ImageUploadInput}
          required
          inputProps={{
            isEditing,
            empty: (
              <Box p={4}>
                <Text fontWeight={"bold"} textAlign={"center"} mb={3}>
                  No logo added
                </Text>
                <Text textAlign={"center"}>
                  {" "}
                  If no logo is uploaded, the dashboard will display “Your
                  Privacy Dashboard.”
                </Text>
              </Box>
            ),
            note: "Logo will display at the top of the customer dashboard. For best results logos should be uploaded at 1000px by 500px.",
          }}
        />
        <Box>
          <FormField
            name={"privacy_policy_link.value"}
            Input={Input}
            inputProps={{
              disabled: !isEditing,
              placeholder: "e.g. dataprotectionofficer@yourwebsite.com",
            }}
            label={"Privacy policy link"}
            required
          />
          {privacySettings?.privacy_policy_link?.updated_at && (
            <Text color={"gray.600"} fontSize={"sm"} mt={2}>
              Last added on{" "}
              {new Date(
                privacySettings.privacy_policy_link.updated_at
              ).toLocaleDateString("en-GB", {
                month: "long",
                day: "2-digit",
                year: "numeric",
              })}
            </Text>
          )}
        </Box>
      </Grid>
    </Form>
  );
}
