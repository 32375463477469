import { SVGProps } from "react";

export function Ellipsis(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={"18"}
      height={"4"}
      viewBox={"0 0 18 4"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...props}
    >
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M0.28125 2C0.28125 0.912689 1.16269 0.03125 2.25 0.03125H2.26125C3.34856 0.03125 4.23 0.912689 4.23 2C4.23 3.08731 3.34856 3.96875 2.26125 3.96875H2.25C1.16269 3.96875 0.28125 3.08731 0.28125 2ZM7.03125 2C7.03125 0.912689 7.91269 0.03125 9 0.03125H9.01125C10.0986 0.03125 10.98 0.912689 10.98 2C10.98 3.08731 10.0986 3.96875 9.01125 3.96875H9C7.91269 3.96875 7.03125 3.08731 7.03125 2ZM13.7812 2C13.7812 0.912689 14.6627 0.03125 15.75 0.03125H15.7613C16.8486 0.03125 17.73 0.912689 17.73 2C17.73 3.08731 16.8486 3.96875 15.7613 3.96875H15.75C14.6627 3.96875 13.7812 3.08731 13.7812 2Z"
        }
        fill={"#2D3748"}
      />
    </svg>
  );
}
