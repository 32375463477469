import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle({});

const commonStyles = {
  container: {
    height: "max-content",
    lineHeight: "24px",
    size: 16,

    borderRadius: 4,
  },
  title: {
    fontWeight: "bold",
    color: "gray.900",
  },
  icon: {
    width: 5,
    height: 5,
    marginTop: "1.5px",
  },
};

const successStatusVariant = definePartsStyle({
  container: {
    ...commonStyles.container,
    background: "green.50",
  },
  title: commonStyles.title,
  icon: commonStyles.icon,
});

const errorStatusVariant = definePartsStyle({
  container: {
    ...commonStyles.container,
    background: "red.50",
  },
  title: commonStyles.title,
  icon: commonStyles.icon,
});

const warningStatusVariant = definePartsStyle({
  container: {
    ...commonStyles.container,
    background: "orange.50",
    boxShadow: "none",
  },
  title: commonStyles.title,
  icon: commonStyles.icon,
});

export const Alert = defineMultiStyleConfig({
  baseStyle,
  variants: {
    subtle: ({ status }) => {
      if (status === "error") return errorStatusVariant;
      else if (status === "warning") return warningStatusVariant;
      return successStatusVariant;
    },
  },
});
