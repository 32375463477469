import { RouteArray } from "routes";
import { ConsentPreferences } from "features/customer-dashboard/screens/consent-preferences";
import { History } from "features/customer-dashboard/screens/history";
import { DataRequests } from "features/customer-dashboard/screens/data-requests";
import { CustomerDashboard } from "features/customer-dashboard/screens";
import { Navigate } from "@tanstack/react-location";
import { Contact } from "features/customer-dashboard/screens/contact";
import { customerDashboardTheme } from "features/customer-dashboard/theme";
import { queryClient } from "lib/react-query";
import { getConsentPreferences } from "features/customer-dashboard/api/consent-preferences";
import { Container, Spinner } from "@chakra-ui/react";
import { DataRequestForm } from "features/customer-dashboard/screens/data-requests/request-form";
import { DATA_REQUESTS } from "features/customer-dashboard/screens/data-requests/constants";
import { DataRequestPage } from "features/customer-dashboard/screens/data-requests/request-page";
import { AppError } from "errors";

/**
 * Note that we are applying a top-level route, which renders
 * a component that has an <Outlet /> within it to render the children
 *
 * This lets us establish shared nav etc, and also lets us apply a theme.
 */
export const routes: RouteArray = [
  {
    path: "/customer/:id",
    meta: {
      title: "Consent Preferences",
      theme: customerDashboardTheme,
    },
    element: <CustomerDashboard />,
    children: [
      {
        path: "/preferences",
        element: <ConsentPreferences />,
        loader: async ({ params, search }) => {
          if (!search.token) throw new AppError("NO TOKEN");
          return {
            preferences: await queryClient.ensureQueryData(
              getConsentPreferences({
                remoteContactId: params.id,
                token: search.token,
              })
            ),
          };
        },
        pendingElement: async () => {
          return (
            <Container>
              <Spinner size={"lg"} />
            </Container>
          );
        },
        meta: {
          title: "Consent Preferences",
          showTabs: true,
        },
      },
      {
        path: "/data-requests",
        element: <DataRequests />,
        meta: {
          title: "Data Requests",
        },
        children: [
          {
            path: "/",
            element: <DataRequestPage />,
            meta: {
              showTabs: true,
            },
          },
          {
            path: "/copy",
            element: <DataRequestForm {...DATA_REQUESTS.COPY} />,
          },
          {
            path: "/correction",
            element: <DataRequestForm {...DATA_REQUESTS.CORRECTION} />,
          },
          {
            path: "/removal",
            element: <DataRequestForm {...DATA_REQUESTS.REMOVAL} />,
          },
        ],
      },
      {
        path: "/history",
        element: <History />,
        meta: {
          title: "Consent Preferences",
        },
      },
      {
        path: "/contact",
        element: <Contact />,
        meta: {
          title: "Consent Preferences",
        },
      },
      {
        element: <Navigate to={"preferences"} search replace />,
      },
    ],
  },
];
