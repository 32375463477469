import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import {
  RegisterOptions,
  useController,
  useFormContext,
} from "react-hook-form";
import { ComponentPropsWithRef, ComponentType, ReactNode } from "react";

type FormFieldProps<P extends ComponentType<any>> = {
  name: string;
  label?: ReactNode;
  Input: P;
  inputProps?: ComponentPropsWithRef<P>;
} & RegisterOptions;

export function FormField<P extends ComponentType<any>>({
  label,
  Input,
  inputProps,
  name,
  required,
  ...registerOptions
}: FormFieldProps<P>) {
  const { control } = useFormContext();

  const { field, fieldState } = useController({ control, name });

  const props = {
    ...field,
    ...registerOptions,
    ...(Input === Checkbox ? { isChecked: field.value } : {}),
    ...inputProps,
  } as ComponentPropsWithRef<P>;

  return (
    <FormControl isInvalid={!!fieldState.error}>
      {label ? (
        <FormLabel htmlFor={name} display={"flex"} me={"0"} gap={1}>
          {label} {!required && <Text color={"gray.600"}>— optional</Text>}
        </FormLabel>
      ) : null}
      <Input {...props} />
      {fieldState.error?.message ? (
        <FormErrorMessage>
          {fieldState.error?.message as string}
        </FormErrorMessage>
      ) : null}
    </FormControl>
  );
}
