import { Avatar, Box, chakra, Flex, Text } from "@chakra-ui/react";
import { Activity } from "features/customer-dashboard/api/customer";
import { getElapsedTime } from "utils/date";

export function HistoryItem({
  title,
  details,
  ip_address,
  url,
  author_name,
  created_at,
}: Activity) {
  return (
    <Box display={"flex"} flexDir={"row"} gap={5}>
      <Avatar size={"sm"} name={author_name} />
      <Box flex={1} minW={0} display={"flex"} flexDir={"column"} gap={2}>
        <Flex wrap={"wrap"}>
          <Text mr={3} dangerouslySetInnerHTML={{ __html: title }} />
          <Text>{getElapsedTime(new Date(created_at))}</Text>
        </Flex>
        <Text>{details}</Text>
        <Text color={"gray.600"} fontSize={"sm"}>
          {ip_address}{" "}
          <chakra.span
            color={"gray.600"}
            mx={1}
            userSelect={"none"}
            aria-hidden
          >
            •
          </chakra.span>{" "}
          {url}
        </Text>
      </Box>
    </Box>
  );
}
