import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { Outlet } from "@tanstack/react-location";
import { HeaderNav } from "features/privacy-app/patterns/header-nav";
import { NavMenu } from "features/privacy-app/patterns/nav-menu";
import { ReactNode } from "react";
import { useAuthentication } from "features/privacy-app/api/authentication";

interface PageHeaderProps {
  title: string;
  description?: string;
  actions?: ReactNode;
}

export const PageHeader = ({
  title,
  description,
  actions,
}: PageHeaderProps) => {
  return (
    <Flex
      direction={"column"}
      bg={"white"}
      borderRadius={2}
      minH={"88px"}
      w={"100%"}
      p={6}
      justify={"center"}
    >
      <Flex w={"full"} align={"center"} justify={"space-between"}>
        <Heading size={"md"}>{title}</Heading>
        {actions && actions}
      </Flex>

      {description && (
        <Text fontSize={"lg"} mt={5}>
          {description}
        </Text>
      )}
    </Flex>
  );
};

export function Layout() {
  const { getUser } = useAuthentication();

  if (!getUser().data) return null;

  return (
    <Flex bg={"gray.50"}>
      <NavMenu />
      <Box w={"100%"}>
        <HeaderNav />
        <Box px={10} py={6}>
          <Outlet />
        </Box>
      </Box>
    </Flex>
  );
}
