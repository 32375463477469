import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
} from "@chakra-ui/react";

interface ErrorAlertProps {
  error?: Error | null;
}

export function ErrorAlert({ error }: ErrorAlertProps) {
  if (!error) return null;
  return (
    <Alert status={"error"} alignItems={"flex-start"} shadow={"none"} mb={"4"}>
      <AlertIcon />
      <Box>
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Box>
    </Alert>
  );
}
