import { SVGProps } from "react";

export function Trash(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={"12"}
      height={"12"}
      viewBox={"0 0 12 12"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...props}
    >
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M3.75 0.75C3.75 0.335786 4.08579 0 4.5 0H7.5C7.91421 0 8.25 0.335786 8.25 0.75C8.25 1.16421 7.91421 1.5 7.5 1.5H4.5C4.08579 1.5 3.75 1.16421 3.75 0.75ZM0.75 3C0.75 2.58579 1.08579 2.25 1.5 2.25H10.5C10.9142 2.25 11.25 2.58579 11.25 3C11.25 3.41421 10.9142 3.75 10.5 3.75V9.98077C10.5 11.0854 9.61559 12 8.5 12H3.5C2.38441 12 1.5 11.0854 1.5 9.98077V3.75C1.08579 3.75 0.75 3.41421 0.75 3ZM3 3.75V9.98077C3 10.2786 3.23434 10.5 3.5 10.5H8.5C8.76566 10.5 9 10.2786 9 9.98077V3.75H3ZM4.2696 5.46967C4.56249 5.17678 5.03736 5.17678 5.33026 5.46967L5.99993 6.13934L6.6696 5.46967C6.96249 5.17678 7.43736 5.17678 7.73026 5.46967C8.02315 5.76256 8.02315 6.23744 7.73026 6.53033L7.06059 7.2L7.73026 7.86967C8.02315 8.16256 8.02315 8.63744 7.73026 8.93033C7.43736 9.22322 6.96249 9.22322 6.6696 8.93033L5.99993 8.26066L5.33026 8.93033C5.03736 9.22322 4.56249 9.22322 4.2696 8.93033C3.9767 8.63744 3.9767 8.16256 4.2696 7.86967L4.93927 7.2L4.2696 6.53033C3.9767 6.23744 3.9767 5.76256 4.2696 5.46967Z"
        }
        fill={"#2D3748"}
      />
    </svg>
  );
}
