import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Form } from "components/form";
import { FormField } from "components/form-field";
import {
  consentPreferencesToFormData,
  useConsentPreferences,
  FormData,
  formDataToConsentPreferences,
} from "features/customer-dashboard/api/consent-preferences";
import { Checkbox } from "features/customer-dashboard/components/input/checkbox";

const CONTACT_TYPES = [
  {
    value: "is_sms_enabled",
    label: "SMS",
  },
  {
    value: "is_phone_call_enabled",
    label: "Phone",
  },
  {
    value: "is_email_enabled",
    label: "Email",
  },
  {
    value: "is_letter_enabled",
    label: "Letter",
  },
];

export function ConsentPreferences() {
  const toast = useToast({
    position: "top",
  });

  const {
    getConsentPreferences,
    updateConsentPreferences: updateConsentPreferencesQuery,
  } = useConsentPreferences();

  const { data: consentPreferences } = getConsentPreferences();

  const form = useForm<FormData>({
    defaultValues: consentPreferencesToFormData(consentPreferences),
  });
  const { formState } = form;
  const { dirtyFields } = formState;

  const { mutate: updateConsentPreferences, isLoading } =
    updateConsentPreferencesQuery({
      onSuccess: (newConsentPreferences) => {
        toast({
          status: "success",
          title: "Your preferences have been updated",
        });
        form.reset(consentPreferencesToFormData(newConsentPreferences));
      },
    });

  const onSubmit = (formData: FormData) => {
    // Sends only updated fields by comparing formData to dirtyFields
    updateConsentPreferences({
      profile_id: consentPreferences?.profile_id,
      ...formDataToConsentPreferences(
        formData,
        dirtyFields as Partial<Record<keyof FormData, boolean>>
      ),
    });
  };

  // consentPreferences should have a value because this page has a loader
  if (!consentPreferences) {
    throw Error("Undefined consent preferences");
  }

  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      submitLoading={isLoading}
      enableSaveCancelOverlay
    >
      <VStack spacing={[6, 8]} align={"flex-start"} mb={[8, 16]}>
        <Heading as={"h1"} size={"lg"}>
          Manage your preferences
        </Heading>
        <Text color={"gray.600"}>
          Select your communication preferences and how you would like to be
          contacted.
        </Text>
      </VStack>
      <Grid templateColumns={"repeat(12, 1fr)"} columnGap={"8"}>
        <GridItem colSpan={[12, 12, 4]} rowSpan={1} mb={[8, 16]}>
          <Heading as={"h2"} size={"md"}>
            How should we contact you?
          </Heading>
        </GridItem>
        <GridItem colSpan={[12, 12, 8]} rowSpan={1}>
          <Grid templateColumns={"1fr 1fr"} gap={4}>
            {CONTACT_TYPES.map((contact) => (
              <GridItem key={contact.value}>
                <FormField
                  Input={Checkbox}
                  inputProps={{
                    label: contact.label,
                    variant: "card",
                  }}
                  name={contact.value}
                />
              </GridItem>
            ))}
          </Grid>
        </GridItem>
        <GridItem colSpan={12} rowSpan={1} my={[8, 16]}>
          <Divider />
        </GridItem>
        <GridItem colSpan={[12, 12, 4]} rowSpan={1} mb={[8, 16]}>
          <Heading as={"h2"} size={"md"}>
            What information do you want to receive?
          </Heading>
        </GridItem>
        <GridItem colSpan={[12, 12, 8]} rowSpan={1}>
          <Flex direction={"column"} gap={4}>
            {consentPreferences.consent_types.map((consentType) => (
              <FormField
                key={consentType.id}
                Input={Checkbox}
                inputProps={{
                  label: consentType.name,
                  subLabel: consentType.description,
                  variant: "card",
                }}
                name={`consent_types.${consentType.id}.is_enabled`}
              />
            ))}
            <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as={"span"} flex={"1"} textAlign={"left"}>
                      Property newsletters
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  <VStack spacing={5} align={"flex-start"}>
                    {consentPreferences.lists.map((newsletter) => (
                      <VStack
                        key={newsletter.group}
                        align={"flex-start"}
                        spacing={5}
                        w={"full"}
                      >
                        <Heading size={"xs"} mb={0}>
                          {newsletter.group}
                        </Heading>
                        {newsletter.items.map((item) => (
                          <FormField
                            key={item.id}
                            Input={Checkbox}
                            inputProps={{
                              label: item.name,
                              variant: "full-width",
                            }}
                            name={`lists.${item.id}.is_enabled`}
                          />
                        ))}
                      </VStack>
                    ))}
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as={"span"} flex={"1"} textAlign={"left"}>
                      Property alerts
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  <VStack spacing={5} align={"flex-start"}>
                    {consentPreferences.campaigns.map((campaign) => (
                      <VStack
                        key={campaign.group}
                        align={"flex-start"}
                        spacing={5}
                        w={"full"}
                      >
                        <Heading size={"xs"} mb={0}>
                          {campaign.group}
                        </Heading>
                        {campaign.items.map((item) => (
                          <FormField
                            key={item.id}
                            Input={Checkbox}
                            inputProps={{
                              label: item.name,
                              variant: "full-width",
                            }}
                            name={`campaigns.${item.id}.is_enabled`}
                          />
                        ))}
                      </VStack>
                    ))}
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Flex>
        </GridItem>
        <GridItem colSpan={12} rowSpan={1} mt={[8, 16]} mb={[4, 8]}>
          <Divider />
        </GridItem>
        <GridItem colSpan={12} rowSpan={1}>
          <FormField
            Input={Checkbox}
            inputProps={{
              label: "Unsubscribe from all the above",
              subLabel:
                "Please note you will still receive communications regarding any ongoing property agreement/contract.",
              variant: "full-width",
            }}
            name={"is_unsubscribed_all_enabled"}
          />
        </GridItem>
        <GridItem colSpan={12} rowSpan={1} mt={[4, 8]}>
          <Divider />
        </GridItem>
      </Grid>
    </Form>
  );
}
