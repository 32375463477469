import * as React from "react";
import { ReactNode, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Router } from "@tanstack/react-location";
import { location } from "lib/react-location";
import { routes } from "routes";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "lib/react-query";
import { ThemeProvider } from "contexts/theme-provider";
import { Provider as ModalProvider } from "@ebay/nice-modal-react";
import { AuthProvider } from "contexts/auth-context";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorScreen } from "components/error/error-screen";
import { BugsnagBoundary, initialiseBugsnag } from "lib/bugsnag";

export function AppProviders({ children }: { children: ReactNode }) {
  useEffect(() => {
    initialiseBugsnag();
  }, []);
  return (
    <React.StrictMode>
      <BugsnagBoundary>
        <HelmetProvider>
          <Router location={location} routes={routes}>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider>
                <ModalProvider>
                  <AuthProvider>
                    <ErrorBoundary FallbackComponent={ErrorScreen}>
                      {children}
                    </ErrorBoundary>
                  </AuthProvider>
                </ModalProvider>
              </ThemeProvider>
            </QueryClientProvider>
          </Router>
        </HelmetProvider>
      </BugsnagBoundary>
    </React.StrictMode>
  );
}
