import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient, RequestPath } from "utils/api-client";
import { Pagination } from "types/pagination";
import { MutationForRecord, QueryForRecord } from "types/query-for-record";
import { queryClient } from "lib/react-query";

export const DATA_SOURCES_STATUS_TYPES = [
  { key: "not_configured", label: "Not Configured" },
  { key: "feeding", label: "Feeding" },
  { key: "dormant", label: "Dormant" },
] as const;

export type StatusType = (typeof DATA_SOURCES_STATUS_TYPES)[number]["key"];

export interface DataSource {
  id: string;
  name: string;
  app_type: string;
  api_secret: string;
  status: StatusType;
  remote_id: string;
}

const DATA_SOURCE_KEY = "data_source";

function getDataSources(page = 1): QueryForRecord<Pagination<DataSource>> {
  return {
    queryKey: [DATA_SOURCE_KEY, page],
    queryFn: async () => {
      return (await apiClient.get(
        ("/data-sources?page=" + page) as RequestPath
      )) as Pagination<DataSource>;
    },
    keepPreviousData: true,
  };
}

interface UpsertParams {
  onSuccess?: (args: DataSource) => void;
}

interface DeleteParams {
  onSuccess?: () => void;
}

function upsertDataSource({
  onSuccess,
}: UpsertParams): MutationForRecord<DataSource> {
  return {
    mutationFn: async (dataSource) => {
      if (dataSource.id) {
        const res = await apiClient.patch<DataSource>(
          `/data-sources/${dataSource.id}`,
          dataSource
        );
        return res.data;
      }

      const res = await apiClient.post<DataSource>("/data-sources", dataSource);
      return res.data;
    },
    onSuccess: (dataSource) => {
      queryClient.invalidateQueries({
        queryKey: [DATA_SOURCE_KEY],
      });
      onSuccess?.(dataSource);
    },
    meta: {
      showErrorModal: false,
    },
  };
}

function deleteDataSource({
  onSuccess,
}: DeleteParams): MutationForRecord<DataSource> {
  return {
    mutationFn: async (dataSource) => {
      await apiClient.delete(`/data-sources/${dataSource.id}`);
      return dataSource;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DATA_SOURCE_KEY],
      });
      onSuccess?.();
    },
    meta: {
      showErrorModal: false,
    },
  };
}

export function useDataSource() {
  return {
    getDataSources: (page = 1) => useQuery(getDataSources(page)),
    upsertDataSource: (params: UpsertParams) =>
      useMutation(upsertDataSource(params)),
    deleteDataSource: (params: DeleteParams) =>
      useMutation(deleteDataSource(params)),
  };
}
