import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { useClipboard } from "features/privacy-app/hooks/use-clipboard";

export interface ViewTokenProps {
  connectionToken: string;
}

export const CopyConnectionToken = ({ connectionToken }: ViewTokenProps) => {
  const { copyText, isCopied } = useClipboard({ disableToast: true });
  return (
    <Box>
      <Text mb={5}>
        Copy this code to connect your data source with Single View.
      </Text>
      <Grid templateColumns={"1fr auto"} gap={2}>
        <Input disabled value={connectionToken} />
        <Button
          variant={"ghost"}
          leftIcon={<CopyIcon />}
          disabled={isCopied}
          onClick={() => copyText(connectionToken)}
        >
          {isCopied ? "Copied!" : "Copy token"}
        </Button>
      </Grid>
    </Box>
  );
};

export const ViewConnectionToken = createModal<ViewTokenProps>(
  ({ connectionToken }) => {
    const modal = useModal();

    return (
      <Modal
        isOpen={modal.visible}
        onClose={modal.hide}
        onCloseComplete={modal.remove}
      >
        <ModalOverlay />
        <ModalContent w={600} pb={6}>
          <ModalHeader>Connection token</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CopyConnectionToken connectionToken={connectionToken} />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
);
