import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useConsentPreferences } from "features/customer-dashboard/api/consent-preferences";

export function Footer() {
  const { getConsentPreferences } = useConsentPreferences();

  const { data: consentPreferences } = getConsentPreferences();

  if (!consentPreferences) return null;

  return (
    <Flex justify={"space-between"} mb={16}>
      <Flex align={"center"} gap={6}>
        <Text fontWeight={"semibold"}>
          {consentPreferences.silo_settings.business_name?.value}
        </Text>
        <a href={consentPreferences.silo_settings.privacy_policy_link?.value}>
          <Button variant={"link"} colorScheme={"blue"} whiteSpace={"normal"}>
            Privacy policy
          </Button>
        </a>
      </Flex>
      <Box>Powered by Rex Software</Box>
    </Flex>
  );
}
