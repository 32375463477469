import { ComponentStyleConfig } from "@chakra-ui/react";

export const Checkbox: ComponentStyleConfig = {
  parts: [],
  variants: {
    "full-width": {
      container: {
        display: "flex",
        width: "100%",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
      },
      label: {
        marginInlineStart: "none",
        fontWeight: "600",
      },
      control: {
        width: "20px",
        height: "20px",
      },
    },
    card: () => {
      return {
        container: {
          display: "flex",
          width: "100%",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          px: 4,
          fontWeight: 500,
          bg: "white",
          borderWidth: "1px",
          borderColor: "gray.200",
          borderRadius: "md",
          py: 5,
          transition: "100ms ease-out",
          transitionProperty: "box-shadow, border-color",
          boxShadow: `0px 0px 0px 2px transparent`,
          _hover: {
            borderColor: "transparent",
            transition: "none",
            boxShadow: `0px 0px 0px 2px var(--chakra-colors-brand-500)`,
          },
          _checked: {
            borderColor: "rgba(255,255,255,0)",
            boxShadow: `0px 0px 0px 2px var(--chakra-colors-brand-500)`,
          },
        },
        label: {
          fontSize: "md",
          m: 0,
        },
        control: {
          width: "20px",
          height: "20px",
          borderColor: "gray.200",
        },
      };
    },
  },
};
