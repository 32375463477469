import { MutationForRecord } from "types/query-for-record";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "utils/api-client";

export interface Media {
  id: string | null;
  path: string | null;
  file_name: string | null;
  extension: string | null;
}

function uploadMedia(): MutationForRecord<Media, { file: File }> {
  return {
    mutationFn: async (media) => {
      const res = await apiClient.uploadMedia<Media>("/media", media);
      return res.data;
    },
  };
}

export function useMedia() {
  return {
    uploadMedia: () => useMutation(uploadMedia()),
  };
}
