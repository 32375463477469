import { Input, Text, Textarea } from "@chakra-ui/react";
import { ControllerRenderProps, FieldValues } from "react-hook-form";

interface CountedTextInputProps
  extends Partial<ControllerRenderProps<FieldValues, string>> {
  max: number;
  placeholder?: string;
  Input: typeof Input | typeof Textarea;
}

export function CountedTextInput({
  max,
  value,
  Input,
  ...rest
}: CountedTextInputProps) {
  return (
    <>
      <Input {...rest} value={value} maxLength={max} />
      <Text color={"gray.600"} fontSize={"sm"} mt={2}>
        {value?.length ?? 0}/{max}
      </Text>
    </>
  );
}
