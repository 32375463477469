import { responseLoginIfUnauthorised } from "utils/api-client/interceptors/response/response-login-if-unauthorised";
import { RequestConfig, RequestPath } from "utils/api-client/index";
import { responseCsrfToken } from "utils/api-client/interceptors/response/response-csrf-token";
import { requestSendCsrfToken } from "utils/api-client/interceptors/request/request-send-csrf-token";
import { requestEnsureOriginSet } from "utils/api-client/interceptors/request/request-ensure-origin-set";

export type ResponseInterceptor = (args: {
  response: Response;
  path: RequestPath;
  config: RequestConfig;
}) => Promise<Response>;

export type RequestInterceptor = (args: {
  request: Request;
  path: RequestPath;
}) => Promise<Request>;

const defaultResponseInterceptors = [
  responseCsrfToken,
  responseLoginIfUnauthorised,
];

const defaultRequestInterceptors = [
  requestEnsureOriginSet,
  requestSendCsrfToken,
];

export let responseInterceptors: Set<ResponseInterceptor> = new Set(
  defaultResponseInterceptors
);

export let requestInterceptors: Set<RequestInterceptor> = new Set(
  defaultRequestInterceptors
);

export function resetInterceptorDefaults() {
  responseInterceptors = new Set(defaultResponseInterceptors);
  requestInterceptors = new Set(defaultRequestInterceptors);
}
