import { MutationForRecord, QueryForRecord } from "types/query-for-record";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "utils/api-client";
import { Pagination } from "types/pagination";
import { filterFromList, updateList } from "utils/react-query";

export interface ConsentType {
  id: string;
  name: string;
  description: string;
  changed_by_id: string;
  archived_at: string | null;
  changed_by: {
    user_id: string;
    first_name: string;
    last_name: string;
    email: string;
  };
}

interface ListParams {
  archived: 0 | 1;
}

interface UpsertParams {
  onSuccess?: () => void;
}

interface ArchiveParams {
  action: "archive" | "unarchive";
}

const CONSENT_TYPE_KEY = "consent_type";

function getConsentTypes({
  archived,
}: ListParams): QueryForRecord<Pagination<ConsentType>> {
  return {
    queryKey: [CONSENT_TYPE_KEY, { archived }],
    queryFn: async () => {
      return (await apiClient.get("/privacy/consent-types", {
        archived,
      })) as Pagination<ConsentType>;
    },
  };
}

function upsertConsentType({
  onSuccess,
}: UpsertParams): MutationForRecord<ConsentType> {
  return {
    mutationFn: async (consentType) => {
      if (consentType.id) {
        const res = await apiClient.put<ConsentType>(
          `/privacy/consent-types/${consentType.id}`,
          consentType
        );
        return res.data;
      }

      const res = await apiClient.post<ConsentType>(
        "/privacy/consent-types",
        consentType
      );
      return res.data;
    },
    onSuccess: (consentType) => {
      updateList([CONSENT_TYPE_KEY, { archived: 0 }], consentType);

      onSuccess?.();
    },
    meta: {
      showErrorModal: false,
    },
  };
}

function toggleArchiveOfConsentType({
  action,
}: ArchiveParams): MutationForRecord<ConsentType> {
  return {
    mutationFn: async (consentType) => {
      if (action === "archive") {
        const res = await apiClient.delete<ConsentType>(
          `/privacy/consent-types/${consentType.id}`
        );
        return res.data;
      }

      const res = await apiClient.patch<ConsentType>(
        `/privacy/consent-types/${consentType.id}/restore`
      );
      return res.data;
    },
    onSuccess: (consentType) => {
      // Filter to the current list
      filterFromList(
        [CONSENT_TYPE_KEY, { archived: action === "archive" ? 0 : 1 }],
        consentType
      );
      // Add to the opposite list
      updateList(
        [CONSENT_TYPE_KEY, { archived: action === "archive" ? 1 : 0 }],
        consentType
      );
    },
  };
}

export function useConsentType() {
  return {
    getConsentTypes: (params: ListParams) => useQuery(getConsentTypes(params)),
    upsertConsentType: (params: UpsertParams) =>
      useMutation(upsertConsentType(params)),
    toggleArchiveOfConsentType: (params: ArchiveParams) =>
      useMutation(toggleArchiveOfConsentType(params)),
  };
}
