import { Link, Text } from "@chakra-ui/react";
import { CellProps } from "components/table";
import { APP_TYPES } from "features/privacy-app/utils/constants";

export function LinkCell({
  value: link,
  row: {
    remote_id,
    data_source: { app_type },
  },
}: CellProps) {
  if (app_type === APP_TYPES.REX_CRM && remote_id) {
    return (
      <Link href={link} isExternal color={"brand.500"}>
        {remote_id}
      </Link>
    );
  }
  return <Text fontSize={"md"}>{remote_id || "-"}</Text>;
}
