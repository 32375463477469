import { SmallAddIcon } from "@chakra-ui/icons";
import { Box, Button } from "@chakra-ui/react";
import { useModal } from "@ebay/nice-modal-react";
import { Panel } from "features/privacy-app/components/panel";
import { PageHeader } from "features/privacy-app/patterns/layout";
import { DataSourcesTable } from "features/privacy-app/screens/data-sources/components/data-sources-table";
import { UpsertDataSource } from "features/privacy-app/screens/data-sources/modals/upsert-data-source";

export function DataSources() {
  const modal = useModal(UpsertDataSource);
  return (
    <Box>
      <PageHeader
        title={"Data sources"}
        description={
          "Connect and manage your data sources. To add a data source, you must generate a connection token and add this in your data source’s platform settings."
        }
        actions={
          <Button
            leftIcon={<SmallAddIcon boxSize={6} />}
            size={"md"}
            onClick={() => modal.show()}
          >
            Add data source
          </Button>
        }
      />

      <Panel>
        <DataSourcesTable />
      </Panel>
    </Box>
  );
}
