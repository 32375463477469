import { QueryKey } from "@tanstack/react-query";
import { queryClient } from "lib/react-query";
import { Pagination } from "types/pagination";
import { upsert } from "utils/collection";

export function updateList<T extends { id: string }>(
  queryKey: QueryKey,
  item: T
) {
  queryClient.setQueryData<Pagination<T>>(queryKey, (prev) => {
    if (!prev) return prev;
    return {
      ...prev,
      data: upsert<T>(prev.data, item as T),
    };
  });
}

export function filterFromList<T extends { id: string }>(
  queryKey: QueryKey,
  item: T
) {
  queryClient.setQueryData<Pagination<T>>(queryKey, (prev) => {
    if (!prev) return prev;
    return {
      ...prev,
      data: prev.data.filter((prevItem) => prevItem.id !== item.id),
    };
  });
}
