import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon, IconProps } from "@chakra-ui/icons";
import {
  ComponentType,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import {
  DataRequestsIcon,
  DataSourcesIcon,
  Logo,
  PrivacyIcon,
  UsersIcon,
} from "features/privacy-app/components/icons";
import { Link, useLocation } from "@tanstack/react-location";
import { ProfilesIcon } from "features/privacy-app/components/icons/profiles-icon";

function NavMenuButton({
  label,
  path,
  Icon,
  isActive,
}: {
  label: ReactNode;
  path: string;
  Icon: ComponentType<IconProps>;
  isActive?: boolean;
}) {
  return (
    <Link to={path} style={{ width: "100%" }}>
      <Button
        w={"100%"}
        pl={3}
        py={6}
        fontWeight={"medium"}
        color={"white"}
        invert={1}
        overflow={"hidden"}
        _hover={{ backgroundColor: "brand.500" }}
        _active={{ backgroundColor: "brand.500" }}
        justifyContent={"flex-start"}
        leftIcon={<Icon boxSize={4} ml={1} mr={2} />}
        variant={"ghost"}
        isActive={isActive}
      >
        {label}
      </Button>
    </Link>
  );
}

function NavHeader() {
  return (
    <HStack mt={3} mb={8} p={1} spacing={3} overflow={"hidden"}>
      <Flex w={10} h={10}>
        <Logo />
      </Flex>
      <Text
        fontSize={"xl"}
        fontWeight={"bold"}
        color={"white"}
        whiteSpace={"nowrap"}
      >
        Single View
      </Text>
    </HStack>
  );
}

interface NavFooterProps {
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
}

function NavFooter({ isCollapsed, setIsCollapsed }: NavFooterProps) {
  return (
    <Flex
      w={"100%"}
      justify={"flex-end"}
      p={3}
      pos={"absolute"}
      bottom={0}
      left={0}
      bgColor={"teal.700"}
    >
      <IconButton
        onClick={() => setIsCollapsed((prev) => !prev)}
        aria-label={"toggle-sidebar"}
        variant={"ghost"}
        icon={isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        _hover={{ backgroundColor: "brand.500" }}
        _active={{ backgroundColor: "brand.500" }}
        color={"white"}
      />
    </Flex>
  );
}

const MENU_ITEMS = [
  {
    label: "Data requests",
    path: "/privacy-app/data-requests",
    Icon: DataRequestsIcon,
  },
  {
    label: "Privacy",
    path: "/privacy-app/privacy",
    Icon: PrivacyIcon,
  },
  {
    label: "Users",
    path: "/privacy-app/users",
    Icon: UsersIcon,
  },
  {
    label: "Data sources",
    path: "/privacy-app/data-sources",
    Icon: DataSourcesIcon,
  },
  {
    label: "Profiles",
    path: "/privacy-app/profiles",
    Icon: ProfilesIcon,
  },
];

export function NavMenu() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { current } = useLocation();
  return (
    <Box
      w={isCollapsed ? "64px" : "224px"}
      bg={"brand.900"}
      left={0}
      h={"100vh"}
      top={0}
      bottom={0}
      zIndex={2}
      flexShrink={0}
      pos={"sticky"}
      transition={"ease-in-out 0.2s"}
    >
      <Box p={2}>
        <NavHeader />
        <VStack spacing={2}>
          {MENU_ITEMS.map((menu) => (
            <NavMenuButton
              key={menu.label}
              isActive={current.pathname.includes(menu.path)}
              {...menu}
            />
          ))}
        </VStack>
      </Box>
      <NavFooter isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
    </Box>
  );
}
