import { ErrorResponseBody, GenericResponse } from "types/api";
import {
  requestInterceptors,
  responseInterceptors,
} from "utils/api-client/interceptors";
import { FetchClientError, FetchServerError } from "errors/fetch-error";
import {
  getLocalStorage,
  STORAGE_KEYS,
} from "features/privacy-app/utils/local-storage";
import { buildRequest, cloneRequest } from "utils/api-client/utils";

export type RequestPath = `/${string}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RequestBody = Record<any, any>;

export type RequestConfig = Omit<RequestInit, "headers"> & {
  headers?: Headers | Record<string, string | undefined>;
};

export const DEFAULT_API_ORIGIN = import.meta.env.VITE_API_ORIGIN;

let baseOrigin: string | null = null;

export function getBaseApiOrigin(): string | null {
  return baseOrigin;
}

export function setBaseApiOrigin(url: string | null) {
  baseOrigin = url;
}

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

// There is no trycatch because react-query will handle it
async function http<R>(
  path: RequestPath,
  config: RequestConfig
): Promise<GenericResponse<R>> {
  const siloId = getLocalStorage(STORAGE_KEYS.SILO_ID);

  const headers = {
    ...DEFAULT_HEADERS,
    ...(config?.headers ? config.headers : {}),
    ...(siloId ? { "Silo-ID": siloId } : {}),
  };

  let request = buildRequest(path, { ...config, headers });

  for (const interceptor of requestInterceptors) {
    request = await interceptor({ request, path });
  }

  // Reinstantiate request in case interceptors change origin
  request = await cloneRequest(request, path, config);

  let response = await fetch(request, { credentials: "include" });

  for (const interceptor of responseInterceptors) {
    // Note that we call clone() on the response, this
    // enables multiple repeated usages of .json()
    // https://github.com/whatwg/fetch/issues/196#issuecomment-171935172
    response = await interceptor({
      response: response.clone(),
      path,
      config,
    });
  }

  if (response.headers.get("Content-Type") !== "application/json")
    return { data: null as R, response };

  const body = await response.clone().json();
  body.response = response;

  if (!response.ok) {
    const errorResponse = body as ErrorResponseBody;

    if (response.status >= 400 && response.status < 500) {
      throw new FetchClientError(
        errorResponse.message,
        request,
        response,
        body
      );
    } else {
      throw new FetchServerError(
        errorResponse.message,
        request,
        response,
        body
      );
    }
  }

  return body;
}

async function get<R>(
  path: RequestPath,
  params?: Record<string, unknown>,
  config?: RequestConfig
): Promise<GenericResponse<R>> {
  let stringParams = "";
  if (params) {
    // Type assertion is added here so that we can still pass number | string
    // using Record<string, unknown> in GetArgs
    stringParams = "?" + new URLSearchParams(params as Record<string, string>);
  }
  return await http<R>(`${path}${stringParams}`, { method: "GET", ...config });
}

async function post<R>(
  path: RequestPath,
  body?: RequestBody,
  config?: RequestConfig
): Promise<GenericResponse<R>> {
  return await http<R>(path, {
    method: "POST",
    body: JSON.stringify(body),
    ...config,
  });
}

async function put<R>(
  path: RequestPath,
  body?: RequestBody,
  config?: RequestConfig
): Promise<GenericResponse<R>> {
  return await http<R>(path, {
    method: "PUT",
    body: JSON.stringify(body),
    ...config,
  });
}

// "delete" is a reserved word
async function deleteMethod<R>(
  path: RequestPath,
  config?: RequestConfig
): Promise<GenericResponse<R>> {
  return await http<R>(path, {
    method: "DELETE",
    ...config,
  });
}

async function patch<R>(
  path: RequestPath,
  body?: RequestBody,
  config?: RequestConfig
): Promise<GenericResponse<R>> {
  return await http<R>(path, {
    method: "PATCH",
    body: JSON.stringify(body),
    ...config,
  });
}

async function uploadMedia<R>(
  path: RequestPath,
  body: {
    file: File;
  },
  config?: RequestConfig
): Promise<GenericResponse<R>> {
  const formData = new FormData();
  formData.append("file", body.file);

  return await http<R>(path, {
    method: "POST",
    body: formData,
    // We need to have empty headers for using formdata in media upload
    // See this article: https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
    headers: {
      "Content-Type": undefined,
      Accept: undefined,
    },
    ...config,
  });
}

export const apiClient = {
  http,
  get,
  post,
  put,
  delete: deleteMethod,
  patch,
  uploadMedia,
};
