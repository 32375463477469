import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { ConnectionToken } from "features/privacy-app/screens/data-sources/modals/connection-token";
import { Form } from "components/form";
import { FormField } from "components/form-field";
import { useForm } from "react-hook-form";
import {
  DataSource,
  useDataSource,
} from "features/privacy-app/api/data-sources";
import { useMemo } from "react";
import { ErrorAlert } from "features/privacy-app/components/error-alert";
import { getModifiedFields } from "utils/form";

interface UpsertDataSourceProps {
  dataSource?: DataSource;
}

export const UpsertDataSource = createModal<UpsertDataSourceProps>(
  ({ dataSource }) => {
    const form = useForm<DataSource>({ defaultValues: dataSource });
    const modal = useModal();
    const toast = useToast();
    const viewTokenModal = useModal(ConnectionToken);

    // we could have just used `dataSource` to check if editing or not
    // but we just purposely add it here for readability.
    const isEditing = useMemo(() => !!dataSource?.id, [dataSource]);

    const { upsertDataSource } = useDataSource();
    const {
      mutate: mutateDataSource,
      isLoading,
      error,
    } = upsertDataSource({
      onSuccess: (successDataSource) => {
        if (!isEditing) {
          viewTokenModal.show({
            connectionToken: successDataSource.api_secret,
          });
        }

        toast({
          status: "success",
          title: isEditing ? "Data source updated!" : "New data source added!",
        });

        modal.remove();
      },
    });

    const onSaveOrNext = (values: DataSource) => {
      const modifiedValues = getModifiedFields(
        values,
        form.formState.dirtyFields
      );
      mutateDataSource({
        ...modifiedValues,
        ...(dataSource && { id: dataSource.id }),
      });
    };

    return (
      <Modal
        isOpen={modal.visible}
        onClose={modal.hide}
        onCloseComplete={modal.remove}
      >
        <ModalOverlay />
        <ModalContent w={600}>
          <Form form={form} onSubmit={onSaveOrNext}>
            <ModalHeader>
              {isEditing ? "Rename data source" : "Data source name"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ErrorAlert error={error} />
              {!isEditing && (
                <Text mb={6} color={"gray.600"}>
                  {`Choose a name for your data source. We suggest you use the name of the account e.g. John’s Real Estate. This will be visible in the customer preference dashboard.`}
                </Text>
              )}

              <FormField
                Input={Input}
                label={"Data source name"}
                name={"name"}
                inputProps={{
                  placeholder: "e.g. John’s Real Estate - Rex CRM",
                  defaultValue: dataSource?.name,
                }}
                required
              />
            </ModalBody>
            <ModalFooter>
              <Button variant={"ghost"} onClick={modal.hide}>
                Cancel
              </Button>
              <Button isLoading={isLoading} type={"submit"}>
                {isEditing ? "Save" : "Next"}
              </Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    );
  }
);
