import { colors } from "features/privacy-app/theme/colors";

export const styles = {
  global: {
    html: {
      "--chakra-ui-focus-ring-color": colors.brand["100"],
    },
    body: {
      color: colors.gray["700"],
      fontWeight: "normal",
      overflowY: "scroll",
      fontSize: "0.875rem",
    },
  },
};
