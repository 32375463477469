import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { tagAnatomy } from "@chakra-ui/anatomy";

export const Tag = createMultiStyleConfigHelpers(
  tagAnatomy.keys
).defineMultiStyleConfig({
  baseStyle: {
    container: {
      color: "gray.700",
      borderRadius: "base",
    },
  },
});
