import { ReactNode, useMemo } from "react";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  CardProps,
  Flex,
  Heading,
  HStack,
  Spacer,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

interface PanelProps extends CardProps {
  title?: string;
  tooltipText?: string;
  actions?: ReactNode;
  children?: ReactNode;
  isLoading?: boolean;
}

export const Panel = ({
  title,
  tooltipText,
  actions,
  children,
  isLoading,
  ...rest
}: PanelProps) => {
  const hasHeader = useMemo(() => title || actions, [title, actions]);

  return (
    <Card w={"100%"} shadow={"none"} mt={6} {...rest}>
      {hasHeader && (
        <CardHeader pb={0} pt={4}>
          <Flex>
            <HStack spacing={4}>
              <Heading size={"sm"}>{title}</Heading>
              {tooltipText ? (
                <Tooltip label={tooltipText}>
                  <InfoOutlineIcon />
                </Tooltip>
              ) : null}
            </HStack>
            <Spacer />
            {actions && <Box>{actions}</Box>}
          </Flex>
        </CardHeader>
      )}
      <CardBody>
        {isLoading ? (
          <Flex justify={"center"} align={"center"} py={4}>
            <Spinner size={"lg"} />
          </Flex>
        ) : (
          children
        )}
      </CardBody>
    </Card>
  );
};
