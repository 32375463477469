import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";
import { BaseSyntheticEvent, ReactNode } from "react";
import { SaveCancelBar } from "features/customer-dashboard/components/save-cancel-bar";
import { AnimatePresence } from "framer-motion";
import { Prompt } from "@tanstack/react-location";

type FormProps<Values extends FieldValues> = {
  onSubmit: (data: Values, event?: BaseSyntheticEvent) => void;
  form: UseFormReturn<Values>;
  children: ReactNode;
  enableSaveCancelOverlay?: boolean;
  submitLoading?: boolean;
  promptIfUnsavedChanges?: boolean;
};

export function Form<Values extends FieldValues>({
  form,
  onSubmit,
  children,
  enableSaveCancelOverlay,
  promptIfUnsavedChanges = true,
  submitLoading,
  ...rest
}: FormProps<Values>) {
  const {
    formState: { isDirty },
  } = form;

  const onSubmitWrapper = (data: Values, event?: BaseSyntheticEvent) => {
    event?.preventDefault();
    return onSubmit(data, event);
  };

  return (
    <FormProvider {...form}>
      {/* @ts-expect-error wrong type from react-location */}
      <Prompt
        when={promptIfUnsavedChanges && isDirty}
        message={"There are unsaved changes, are you sure you want to leave?"}
      />
      <form onSubmit={form.handleSubmit(onSubmitWrapper)}>
        {children}
        {enableSaveCancelOverlay && (
          <AnimatePresence>
            {isDirty && <SaveCancelBar form={form} isLoading={submitLoading} />}
          </AnimatePresence>
        )}
      </form>
    </FormProvider>
  );
}
