import { SVGProps } from "react";

export function EmailSuccess(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={"112"}
      height={"112"}
      viewBox={"0 0 112 112"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...props}
    >
      <path
        d={
          "M6.06464 35.4716C-4.44161 58.255 -3.0653 94.8765 24.3976 103.689C47.069 110.97 92.2973 107.026 105.088 83.984C139.538 18.005 35.5202 -28.3949 6.06464 35.4716Z"
        }
        fill={"#E7EDFD"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M21.2178 45.1973C21.2178 45.1973 38.1028 25.5376 44.588 22.412C51.0732 19.2863 72.2234 34.9731 72.2234 34.9731L21.2178 45.1973Z"
        }
        fill={"white"}
      />
      <g filter={"url(#filter0_d_2421_430002)"}>
        <path
          d={
            "M21.2172 45.1967C19.7273 46.9786 26.3001 82.3835 28.8416 82.9677C31.3831 83.5812 78.766 75.3142 79.7008 72.9773C80.6648 70.6403 73.6537 35.9657 72.2515 35.031C70.8492 34.067 22.707 43.4148 21.2172 45.1967Z"
          }
          fill={"white"}
        />
      </g>
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M63.254 47.8557C64.1888 47.7097 67.1102 47.2423 68.4832 46.8625C68.6877 46.8041 68.7752 46.4828 68.7168 46.103L67.8114 41.5167C67.753 41.1662 67.5484 40.9033 67.3439 40.9033C66.2922 40.8741 63.1665 40.8156 61.8811 41.4875C61.735 41.5752 61.6472 41.8381 61.6764 42.1302C61.7933 43.065 62.1146 45.1974 62.7573 47.3299C62.8741 47.6804 63.0495 47.8849 63.254 47.8557Z"
        }
        fill={"#0F2C65"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M35.1518 60.5626C39.2416 59.7739 51.8905 57.3201 58.0544 56.064C58.9308 55.8887 59.6027 55.655 59.5735 55.5674L59.3107 54.2236C59.2815 54.136 58.668 54.1652 57.8501 54.3112C53.4682 55.1 40.2347 57.4953 34.3338 58.7807C33.6035 58.9267 33.0777 59.1312 32.9901 59.2188C32.7856 59.5402 32.4933 60.2705 33.545 60.7087C33.691 60.7963 34.3631 60.7379 35.1518 60.5626Z"
        }
        fill={"#0F2C65"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M35.4146 64.9737C38.4527 64.3895 47.83 62.5783 52.3872 61.6436C53.0299 61.4975 53.5265 61.3222 53.4973 61.2054L53.2345 59.8616C53.2053 59.774 52.7378 59.774 52.1244 59.8616C48.8818 60.4167 39.0369 62.1694 34.6843 63.1626C34.1584 63.2794 33.7496 63.4255 33.6911 63.5423C33.5451 63.8637 33.3699 64.5355 34.1879 65.0613C34.3339 65.1198 34.8303 65.0906 35.4146 64.9737Z"
        }
        fill={"#0F2C65"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M36.0281 69.9107C38.4528 69.4433 45.9022 68.0119 49.5247 67.2524C50.0506 67.1356 50.4301 66.9895 50.4009 66.8727L50.1381 65.5289C50.1089 65.4413 49.7289 65.4121 49.2615 65.4997C46.6615 65.9379 38.8323 67.2816 35.3851 68.0996C34.9469 68.1872 34.6548 68.3333 34.6256 68.4209C34.5087 68.713 34.392 69.4141 35.0931 69.9399C35.1807 69.9983 35.5899 69.9983 36.0281 69.9107Z"
        }
        fill={"#0F2C65"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M36.2618 73.9122C37.0505 73.7662 39.4751 73.328 40.6728 73.0651C40.8481 73.0359 40.9648 72.919 40.9356 72.8022L40.6728 71.4584C40.6436 71.3708 40.5268 71.2832 40.3515 71.3124C39.5044 71.4 36.9336 71.7213 35.7943 72.0719C35.6483 72.1011 35.5608 72.2179 35.5608 72.3056C35.5608 72.5977 35.6192 73.2404 35.9113 73.8246C35.9697 73.883 36.0865 73.9415 36.2618 73.9122Z"
        }
        fill={"#0F2C65"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M42.5725 72.5983C43.3612 72.4522 45.7857 72.014 46.9834 71.7511C47.1587 71.7219 47.2754 71.6051 47.2462 71.4882L46.9834 70.1445C46.9542 70.0568 46.8374 69.9692 46.6621 69.9984C45.815 70.086 43.2442 70.4074 42.1342 70.7579C41.9882 70.7871 41.9007 70.904 41.9007 70.9916C41.9007 71.2837 41.9591 71.9264 42.2512 72.5106C42.2804 72.5691 42.4264 72.6275 42.5725 72.5983Z"
        }
        fill={"#0F2C65"}
      />
      <path
        d={
          "M89.0393 77.6999C96.8394 62.9422 73.2918 52.5634 66.6285 66.8474C64.2496 71.9421 64.5653 80.1314 70.7759 82.1103C75.9023 83.7419 86.1445 82.8577 89.0393 77.6999Z"
        }
        fill={"#7BD0A2"}
      />
      <path
        d={
          "M75.264 76.824L71.3619 73.655C70.9922 73.3474 70.9415 72.8144 71.2304 72.4517C71.538 72.0819 72.071 72.0312 72.4338 72.3202L75.0592 74.4592L82.4403 66.0951C82.7549 65.744 83.2948 65.7119 83.646 66.0265C83.9971 66.341 84.0291 66.881 83.7146 67.2321L75.264 76.824Z"
        }
        fill={"white"}
      />
      <defs>
        <filter
          id={"filter0_d_2421_430002"}
          x={"21"}
          y={"34.9614"}
          width={"60.7915"}
          height={"51.0386"}
          filterUnits={"userSpaceOnUse"}
          colorInterpolationFilters={"sRGB"}
        >
          <feFlood floodOpacity={"0"} result={"BackgroundImageFix"} />
          <feColorMatrix
            in={"SourceAlpha"}
            type={"matrix"}
            values={"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"}
            result={"hardAlpha"}
          />
          <feOffset dx={"2"} dy={"3"} />
          <feColorMatrix
            type={"matrix"}
            values={"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"}
          />
          <feBlend
            mode={"normal"}
            in2={"BackgroundImageFix"}
            result={"effect1_dropShadow_2421_430002"}
          />
          <feBlend
            mode={"normal"}
            in={"SourceGraphic"}
            in2={"effect1_dropShadow_2421_430002"}
            result={"shape"}
          />
        </filter>
      </defs>
    </svg>
  );
}
