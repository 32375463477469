import { chakra, ChakraProps } from "@chakra-ui/react";

export function EditIcon(props: ChakraProps) {
  return (
    <chakra.svg
      width={"18"}
      height={"18"}
      viewBox={"0 0 18 18"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...props}
    >
      <chakra.path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M10.2172 2.71721C10.2023 2.73065 10.1876 2.74455 10.1733 2.75893C10.1589 2.7733 10.145 2.78797 10.1315 2.80291L0.661254 12.2732L0.660137 12.2743C0.235891 12.6969 0 13.2697 0 13.8651V16.8744C0 17.4958 0.50368 17.9994 1.125 17.9994H4.13438C4.72955 17.9994 5.3022 17.7637 5.72474 17.3397L5.72624 17.3382L15.2224 7.84386C15.2285 7.83804 15.2345 7.83215 15.2405 7.82617C15.2464 7.82024 15.2523 7.81426 15.258 7.80824L17.342 5.72468C18.2201 4.84659 18.2201 3.42216 17.342 2.54407L15.4565 0.658567C14.5784 -0.219522 13.154 -0.219522 12.2759 0.658567L10.2172 2.71721ZM12.5609 3.55555L13.8662 2.25024L15.7503 4.13438L14.4449 5.43956L12.5609 3.55555ZM12.8537 7.0304L4.133 15.7494H2.25L2.25012 13.8663L10.9699 5.14654L12.8537 7.0304Z"
        }
        fill={"#2D3748"}
      />
    </chakra.svg>
  );
}
