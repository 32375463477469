import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { tabsAnatomy } from "@chakra-ui/anatomy";

export const Tabs = createMultiStyleConfigHelpers(
  tabsAnatomy.keys
).defineMultiStyleConfig({
  baseStyle: {
    tab: {
      py: false,
      pb: 4,
      pt: 4,
      _selected: {
        borderTopLeftRadius: "md",
        borderTopRightRadius: "md",
      },
    },
  },
  variants: {
    line: {
      tablist: {
        borderBottom: "1px solid",
      },
    },
  },
});
