import { SVGProps } from "react";

export function PasswordError(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={"112"}
      height={"112"}
      viewBox={"0 0 112 112"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      {...props}
    >
      <path
        d={
          "M6.06464 35.4716C-4.44161 58.255 -3.0653 94.8765 24.3976 103.689C47.069 110.97 92.2973 107.026 105.088 83.984C139.538 18.005 35.5202 -28.3949 6.06464 35.4716Z"
        }
        fill={"#F4F3F6"}
      />
      <path
        d={
          "M43.8184 68.0047C43.8184 68.0047 42.3271 46.2576 41.83 43.4926C38.8785 26.9958 59.6631 15.5941 65.2242 35.7879C66.4048 40.1062 65.597 45.7605 65.5659 44.8285L61.1542 44.7353C61.1542 44.7353 62.2417 38.5529 58.3892 34.2966C54.3193 29.8229 48.1367 32.6501 47.9503 41.8771C47.8881 45.2324 49.9698 66.5756 50.5912 68.0979C51.2125 69.6203 45.6514 71.9814 43.8184 68.0047Z"
        }
        fill={"#0F2C65"}
      />
      <g filter={"url(#filter0_d_121_12532)"}>
        <path
          d={
            "M72.9312 45.606C72.9312 45.606 78.6789 72.0755 75.4789 74.4366C72.2788 76.7977 33.4124 86.7393 33.4124 76.3628C33.4124 65.9862 31.4861 47.5012 35.96 45.575C40.4338 43.7109 71.7507 39.61 72.9312 45.606Z"
          }
          fill={"white"}
        />
      </g>
      <path
        d={
          "M78.7584 90.5627C95.6618 83.4881 82.9321 58.2695 67.3229 65.7351C61.7536 68.3962 56.3114 75.5602 60.1976 81.5684C63.4067 86.5261 72.7077 92.9177 78.7584 90.5627Z"
        }
        fill={"#D871A4"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M69.9096 80.3086C69.6224 80.7306 69.4962 81.1205 69.6259 81.2721C69.9347 81.6607 70.59 82.3192 71.2178 81.9378C72.0947 81.4172 77.7981 74.8271 77.8874 74.2273C77.9767 73.6275 76.8069 72.5758 76.0254 72.9804C75.3252 73.377 71.6377 77.8249 69.9096 80.3086Z"
        }
        fill={"white"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M70.9025 73.7179C70.4804 73.4307 70.0905 73.3045 69.9389 73.4342C69.5503 73.743 68.8918 74.3983 69.2732 75.0261C69.7938 75.9031 76.3842 81.6067 76.984 81.696C77.5838 81.7852 78.6355 80.6155 78.2309 79.8339C77.8342 79.1337 73.3788 75.455 70.9025 73.7179Z"
        }
        fill={"white"}
      />
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M52.2724 60.5478C50.6359 59.8317 49.4653 58.2255 49.9964 55.8985C50.5081 53.8915 53.8923 52.6642 55.7742 52.7514C58.0542 52.8579 58.9032 55.5993 58.5603 57.5381C58.2693 59.1871 57.2469 60.2091 56 60.6737V68.8457C53.8728 69.0982 49.6982 69.2244 50.0173 67.7097C50.2755 66.4839 51.4217 63.0264 52.2724 60.5478Z"
        }
        fill={"#0F2C65"}
      />
      <defs>
        <filter
          id={"filter0_d_121_12532"}
          x={"33"}
          y={"42.4663"}
          width={"45.4482"}
          height={"41.5337"}
          filterUnits={"userSpaceOnUse"}
          colorInterpolationFilters={"sRGB"}
        >
          <feFlood floodOpacity={"0"} result={"BackgroundImageFix"} />
          <feColorMatrix
            in={"SourceAlpha"}
            type={"matrix"}
            values={"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"}
            result={"hardAlpha"}
          />
          <feOffset dx={"2"} dy={"3"} />
          <feColorMatrix
            type={"matrix"}
            values={"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"}
          />
          <feBlend
            mode={"normal"}
            in2={"BackgroundImageFix"}
            result={"effect1_dropShadow_121_12532"}
          />
          <feBlend
            mode={"normal"}
            in={"SourceGraphic"}
            in2={"effect1_dropShadow_121_12532"}
            result={"shape"}
          />
        </filter>
      </defs>
    </svg>
  );
}
