import { SmallAddIcon } from "@chakra-ui/icons";
import { Box, Button } from "@chakra-ui/react";
import { useModal } from "@ebay/nice-modal-react";
import { Panel } from "features/privacy-app/components/panel";
import { PageHeader } from "features/privacy-app/patterns/layout";
import { UsersTable } from "features/privacy-app/screens/users/components/users-table";
import { InviteUser } from "features/privacy-app/screens/users/modals/invite-user";

export function Users() {
  const modal = useModal(InviteUser);
  return (
    <Box>
      <PageHeader
        title={"Users"}
        actions={
          <Button
            leftIcon={<SmallAddIcon boxSize={6} />}
            size={"md"}
            onClick={() => modal.show()}
          >
            Invite user
          </Button>
        }
      />

      <Panel>
        <UsersTable />
      </Panel>
    </Box>
  );
}
