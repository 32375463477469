import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Table } from "components/table";
import { Panel } from "features/privacy-app/components/panel";
import { ChangedByCell } from "features/privacy-app/components/table/changed-by-cell";
import { useState } from "react";
import { SmallAddIcon } from "@chakra-ui/icons";
import { useModal } from "@ebay/nice-modal-react";
import { EditConsentType } from "features/privacy-app/screens/privacy/modals/edit-consent-type";
import {
  ConsentType,
  useConsentType,
} from "features/privacy-app/api/consent-type";

const COLUMNS = [
  {
    label: "Name",
    id: "name",
  },
  {
    label: "Description",
    id: "description",
  },
  {
    label: "Changed by",
    id: "changed_by",
    Cell: ChangedByCell,
  },
];

export function ConsentTypes() {
  const editConsentType = useModal(EditConsentType);

  const { getConsentTypes, toggleArchiveOfConsentType } = useConsentType();

  const [tab, setTab] = useState<0 | 1>(0);

  const { data: consentTypes, isLoading } = getConsentTypes({ archived: tab });
  const { mutate: toggleArchive, isLoading: archiveLoading } =
    toggleArchiveOfConsentType({
      action: tab === 0 ? "archive" : "unarchive",
    });

  return (
    <Panel
      title={"Consent types"}
      tooltipText={
        "Customers will be shown active consent types when managing their preferences. Consent types can be associated with one or more features in your connected apps."
      }
      actions={
        <Button
          leftIcon={<SmallAddIcon boxSize={6} />}
          onClick={() => editConsentType.show()}
        >
          Add consent type
        </Button>
      }
    >
      <Tabs isLazy onChange={(index) => setTab(index as 0 | 1)}>
        <TabList>
          <Tab>Active</Tab>
          <Tab>Archived</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Table<ConsentType>
              isLoading={isLoading}
              columns={COLUMNS}
              rows={consentTypes?.data ?? []}
              actions={[
                {
                  label: "Edit type",
                  id: "edit_type",
                  onClick: ({ row }) =>
                    editConsentType.show({ consentType: row }),
                },
                {
                  label: "Archive",
                  id: "archive",
                  onClick: ({ row }) => toggleArchive(row),
                  isLoading: archiveLoading,
                  menuItemProps: {
                    closeOnSelect: false,
                  },
                },
              ]}
              emptyProps={{
                emptyMessage: "No active consent types",
                emptyDesc: "To create a consent type, click below.",
                addLabel: "Add consent type",
                onAdd: () => editConsentType.show(),
              }}
            />
          </TabPanel>
          <TabPanel>
            <Table
              isLoading={isLoading}
              columns={COLUMNS}
              rows={consentTypes?.data ?? []}
              actions={[
                {
                  label: "Unarchive",
                  id: "unarchive",
                  onClick: ({ row }) => toggleArchive(row),
                  isLoading: archiveLoading,
                  menuItemProps: {
                    closeOnSelect: false,
                  },
                },
              ]}
              emptyProps={{
                emptyMessage: "No archived consent types",
                emptyDesc: "Any archived consent types will appear here",
              }}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Panel>
  );
}
