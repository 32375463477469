import { ChakraProvider } from "@chakra-ui/react";
import { ReactNode } from "react";
import { useMatches } from "@tanstack/react-location";
import { toastGlobalPropsOverride } from "features/privacy-app/utils/toast";
import { privacyAppTheme } from "features/privacy-app/theme";

export function ThemeProvider({ children }: { children: ReactNode }) {
  const firstMatchingRoute = [...useMatches()]
    .reverse()
    .find((m) => m.route?.meta?.theme);
  const theme = firstMatchingRoute?.route?.meta?.theme || privacyAppTheme;

  return (
    <ChakraProvider toastOptions={toastGlobalPropsOverride} theme={theme}>
      {children}
    </ChakraProvider>
  );
}
